<script setup>
import soundOnIcon from '@/assets/icons/sound-on.svg'
import unlock from '@/assets/icons/unlock.svg'
import particles from '@/assets/img/particles.svg'
import logoiconGrayRater from '@/assets/logoicon-gray-rater.svg'
import AppHeader from '@/components/AppHeader.vue'
import FooterComponent from '@/components/Footer.vue'
import UploadImage from '@/components/UploadImage.vue'
import Eggplant from '@/components/icons/Eggplant.vue'
import { useMixpanel } from '@/composables/mixpanel'
import cdnFile from '@/helpers/cdnFile'
import safeGenerateRecaptchaToken from '@/helpers/safeGenerateRecaptchaToken.js'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import { useHead } from '@unhead/vue'
import getRecaptchaExpectedAction from 'common/lib/helpers/getRecaptchaExpectedAction.js'
import { v4 as uuidv4 } from 'uuid'
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useToast } from 'vue-toast-notification'
import { useStore } from 'vuex'
import trialConfig from 'common/configs/trial.js'

const headTitle = `${import.meta.env.VITE_BRAND_NAME} | Rate My Cock | AI-Powered`
const headDescription = `Meet Mrs. Aisling Rater - a married but playful wife who's eager to give her genuine opinion on any cock picture you send her way`
const headUrl = `https://${import.meta.env.VITE_DOMAIN_NAME}/rate-my-cock`
const headImage = `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`

useHead({
  title: headTitle,
  meta: [
    { name: 'description', content: headDescription },

    { property: 'og:title', content: headTitle },
    { property: 'og:description', content: headDescription },
    { property: 'og:url', content: headUrl },
    { property: 'og:image', content: headImage },

    { name: 'twitter:title', content: headTitle },
    { name: 'twitter:description', content: headDescription },
    { name: 'twitter:url', content: headUrl },
    { name: 'twitter:image', content: headImage },
  ],
})

const mixpanel = useMixpanel()
let raterSessionId = uuidv4()
let raterSessionTimeElapsed = createTimeElapsedCounter()

mixpanel.track(
  'rater_page_visit',
  {
    rater_session_id: raterSessionId,
    rater_time_elapsed: raterSessionTimeElapsed,
  },
  { source: 'previous' },
)

const $store = useStore()

const $toast = useToast()

const user = computed(() => $store.state.user)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const $axios = inject('axios')

$store.commit('UPDATE_LAST_APP_PAGE')

const uploadedImage = ref(null)
const userImage = ref(null)
const result = ref(null)
const imageData = ref({})
const rating = ref({ description: '', audio: null })

const loadingLimit = 10
const loading = ref(false)
const loadingCounter = ref(loadingLimit)
const loadingIntervalID = ref(0)

const isPlaying = ref(false)
const audio = ref(null)

const fileInput = ref(null)

const lunaNeeded = 50

const resultBanner = ref(null)

const hasExpiredTrial = computed(
  () =>
    (!user.value?.subscription && user.value.cardLast4) ||
    $store.state.user.trialExpired,
)

onMounted(async () => {
  const storeRating = $store.state.freeRating
  if (storeRating.rating) {
    if (user.value && !storeRating.rating.userId) {
      rating.value = storeRating.rating
      uploadedImage.value = true
      const response = await $axios.post('/user/rate-add-user', {
        ratingId: rating.value.id,
      })
      $store.commit('SET_FREE_RATING', {
        rating: response.data,
      })
    } else {
      uploadedImage.value = false
    }
  }
})

function openSubscribePopup(freeLimitHit = 'rater_upload') {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: true,
    freeLimitHit,
  })
}

function openSubscriptionExpiredPopup() {
  $store.commit('SET_SUBSCRIBE_EXPIRED_POPUP', {
    open: true,
    text: 'continue chatting',
  })
}

function openSignupRegister(freeLimitHit = 'rater_upload') {
  $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
    open: true,
    freeLimitHit,
  })
}

function openNoBalancePopup() {
  $store.commit('SET_NO_BALANCE_POPUP', {
    open: true,
  })
}

function setLoading(flag) {
  loading.value = flag
  if (flag) {
    loadingCounter.value = loadingLimit
    loadingIntervalID.value = setInterval(() => {
      loadingCounter.value -= 1
    }, 1000)
  } else {
    clearInterval(loadingIntervalID.value)
  }
}

const loadingDisplayValue = computed(() =>
  loadingCounter.value > 0
    ? loadingCounter.value
    : loadingCounter.value > -10
      ? 'Just a few more seconds...'
      : loadingCounter.value > -20
        ? 'Getting there shortly...'
        : loadingCounter.value > -30
          ? 'Almost there...'
          : loadingCounter.value > -40
            ? 'Hang on tight...'
            : 'Any time now...',
)

const backgroundStyle = {
  backgroundImage: `url(${particles})`,
}

const rate = async (e) => {
  let captchaToken = null

  if (!user.value) {
    captchaToken = await safeGenerateRecaptchaToken(
      getRecaptchaExpectedAction('/user/rate'),
    )
  }

  if (user.value) {
    if (!user.value.subscription && user.value.cardLast4) {
      openSubscriptionExpiredPopup()
      return
    }
  }

  const file = e

  imageData.value.fileType = file.type

  await new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async function () {
      if (file.type === 'image/heic') {
        await $axios
          .post('/image/convert-heic', { image: reader.result })
          .then((res) => {
            imageData.value.data = res.data.buffer
            imageData.value.fileType = res.data.fileType
          })
      } else {
        imageData.value.data = reader.result
      }
      setLoading(true)
      uploadedImage.value = true
      window.scrollTo(0, 0)
      resolve()
    }
  })

  mixpanel.track('rater_rating_start', {
    rater_session_id: raterSessionId,
    rater_time_elapsed: raterSessionTimeElapsed(),
  })
  await $axios
    .post(
      '/user/rate',
      {
        image: imageData.value,
      },
      {
        headers: {
          'Captcha-Token': captchaToken,
        },
      },
    )
    .then((response) => {
      if (user.value && user.value.subscription) {
        $store.commit('CHANGE_LUNA', -lunaNeeded)
      }

      mixpanel.track('rater_rating_end', {
        rater_session_id: raterSessionId,
        rater_time_elapsed: raterSessionTimeElapsed(),
      })
      if (response.data) {
        rating.value = response.data
        if (!('rating' in $store.state.freeRating)) {
          $store.commit('SET_FREE_RATING', {
            rating: rating.value,
          })
        }
        setLoading(false)
      } else {
        setLoading(false)
        return
      }
    })
    .catch((error) => {
      console.error('Error rating:', error)
      uploadedImage.value = false
      setLoading(false)

      if (error.response.status === 451) {
        return
      }

      if (error.response && error.response.status === 429) {
        $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
          open: true,
        })
      }
      const message = e?.response?.data?.message
      $toast.error(message || 'Unable to classify the image')
    })
}

const checkUserBeforeReupload = (event) => {
  mixpanel.track('rater_click_reupload', {
    rater_session_id: raterSessionId,
    rater_time_elapsed: raterSessionTimeElapsed(),
  })
  if (!user.value && 'rating' in $store.state.freeRating) {
    return openSignupRegister()
  } else if (
    user.value &&
    !user.value.subscription &&
    'rating' in $store.state.freeRating
  ) {
    return openSubscribePopup()
  } else if (
    user.value &&
    user.value.luna < lunaNeeded &&
    user.value.subscription
  ) {
    return openNoBalancePopup()
  } else {
    fileInput.value.click()
  }
}

const handleBeforeFileUpload = (file, proceed) => {
  mixpanel.track('rater_click_upload', {
    rater_session_id: raterSessionId,
    rater_time_elapsed: raterSessionTimeElapsed(),
  })
  // Perform custom checks or functionality here
  if (!user.value && 'rating' in $store.state.freeRating) {
    proceed(false)
    return openSignupRegister()
  } else if (
    user.value &&
    !user.value.subscription &&
    'rating' in $store.state.freeRating
  ) {
    proceed(false)
    return openSubscribePopup()
  } else if (
    user.value &&
    user.value.luna < lunaNeeded &&
    user.value.subscription
  ) {
    proceed(false)
    return openNoBalancePopup()
  } else {
    // Call proceed(true) to continue or proceed(false) to cancel
    proceed(true)
  }
}

const reupload = (event) => {
  imageData.value = {}
  rating.value = {}
  if (audio.value) {
    audio.value.pause()
    audio.value = null
    isPlaying.value = false
  }
  const file = event.target.files[0]
  if (file) {
    rate(file)
  }
}

let intervalId

const fetchRatingData = async () => {
  try {
    const response = await $axios.get(`user/rate/${rating.value.id}`)
    const ratingData = response.data

    if (ratingData.audio) {
      rating.value = ratingData
      clearInterval(intervalId)
      audio.value = new Audio(cdnFile(rating.value.audio))
      audio.value.addEventListener('ended', () => {
        isPlaying.value = false
      })
      audio.value.play()
    }
  } catch (error) {
    console.error('Error fetching rating data:', error)
  }
}

const playAudio = async () => {
  if (isPlaying.value) return

  isPlaying.value = true

  if (!rating.value.audio) {
    intervalId = setInterval(fetchRatingData, 1000)
  } else {
    audio.value = new Audio(cdnFile(rating.value.audio))

    audio.value.addEventListener('ended', () => {
      isPlaying.value = false
    })

    audio.value.play()
    mixpanel.track('rater_rating_hear', {
      rater_session_id: raterSessionId,
      rater_time_elapsed: raterSessionTimeElapsed(),
    })
  }
}

watch(rating, async (newVal) => {
  if (newVal) {
    await nextTick() // Wait for DOM update
    // Only scroll on mobile here
    if (result.value && userImage.value && window.innerWidth < 1024) {
      const elementPosition = userImage.value.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - 90

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
  if (audio.value) {
    audio.value.pause()
    audio.value = null
  }
})
</script>

<template>
  <div
    class="bg-[#070917] min-h-full lg:ml-[280px] relative"
    :class="discountPopupOpen ? 'pt-[90px] lg:pt-[64px]' : 'pt-0'"
  >
    <AppHeader>
      <template #title>
        <div class="hidden lg:flex items-center gap-[15px]">
          <p class="text-[25px] text-left leading-[1]">Rate My Cock</p>
        </div>
      </template>
    </AppHeader>
    <div class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]">
      <div class="lg:hidden flex items-center gap-[15px] mb-7">
        <p class="text-[25px] text-left leading-[1]">Rate My Cock</p>
      </div>
      <div class="min-h-full w-full flex flex-col gap-[28px]">
        <template v-if="!uploadedImage">
          <div
            class="relative mt-[50px] lg:mt-[85px] p-6 lg:mb-[34px] rounded-[25px] shadow-lg flex items-center border border-[#141a3db3] min-h-[250px] lg:min-h-[339px] bg-bottom lg:bg-left-bottom bg-no-repeat"
            :style="backgroundStyle"
          >
            <div
              class="hidden lg:block absolute bottom-0 left-0 right-0 lg:right-auto lg:w-2/4"
            >
              <picture>
                <source
                  srcset="/aisling/aislingwaiting.avif"
                  type="image/avif"
                />

                <source
                  srcset="/aisling/aislingwaiting.webp"
                  type="image/webp"
                />

                <img
                  src="/aisling/aislingwaiting.webp"
                  alt="Aisling Rater"
                  width="304"
                  class="mx-auto"
                  loading="eager"
                  fetchpriority="high"
                />
              </picture>
            </div>

            <div
              class="block lg:hidden absolute bottom-0 left-0 right-0 h-[300px]"
            >
              <picture>
                <source
                  srcset="/aisling/aislingwaiting.avif"
                  type="image/avif"
                />
                <source
                  srcset="/aisling/aislingwaiting.webp"
                  type="image/webp"
                />
                <img
                  src="/aisling/aislingwaiting.webp"
                  alt="Aisling Rater"
                  width="304"
                  class="mx-auto h-full w-auto"
                />
              </picture>
            </div>

            <div class="hidden lg:block w-2/4 ml-auto">
              <h1 class="text-3xl leading-[1] mb-[34px]">Hi there, darling</h1>
              <p class="text-xl">
                My name is Mrs. Aisling Rater. I’m actually married… But one of
                my favourite pastimes is rating different things. Upload a
                picture of your cock and I’ll give you my honest opinion :)
              </p>
            </div>
          </div>
          <div class="block lg:hidden w-full">
            <h1 class="text-2xl leading-[1] mb-[28px] lg:mb-[34px]">
              Hi there, darling
            </h1>
            <p class="text-base">
              My name is Mrs. Aisling Rater. I’m actually married… But one of my
              favourite pastimes is rating different things. Upload a picture of
              your cock and I’ll give you my honest opinion :)
            </p>
          </div>
          <UploadImage
            :icon="Eggplant"
            @fileInputChange="rate"
            @beforeFileUpload="handleBeforeFileUpload"
          >
            >
            <template v-slot:text>
              <div class="flex flex-col gap-[15px] lg:gap-[30px] items-center">
                <p class="text-lg lg:text-2xl leading-[1]">
                  Upload a Photo to Rate
                </p>
                <p class="text-sm lg:text-base text-[#ffffff99] leading-[1]">
                  Supported formats: .jpg .png .heic
                </p>
              </div>
            </template>
            <template v-slot:after-button v-if="user && user.subscription">
              <div
                class="text-sm font-semibold text-[#ffffff99] leading-[1] flex gap-2 items-center"
              >
                <img :src="logoiconGrayRater" alt class="w-[22px]" />
                50
              </div>
            </template>
          </UploadImage>
        </template>
        <template v-if="uploadedImage">
          <div
            class="bg-[#0a0d2280] lg:p-[32px] rounded-[35px] border border-[#141a3db3] mt-[30px] lg:mt-0 relative"
          >
            <div
              class="flex flex-row px-[60px] h-[298px] lg:h-full lg:max-h-[526px] rounded-[25px] gap-[30px] lg:border lg:border-[#141a3db3] bg-bottom bg-no-repeat bg-cover justify-between items-end"
              :style="backgroundStyle"
            >
              <div
                class="hidden lg:flex h-[328px] lg:h-full w-full lg:w-2/4 flex items-end justify-center absolute bottom-0 left-0 right-0 lg:relative"
              >
                <picture>
                  <source
                    :srcset="
                      rating.image
                        ? '/aisling/aislingdone.avif'
                        : '/aisling/aislingthinking.avif'
                    "
                    type="image/avif"
                  />
                  <source
                    :srcset="
                      rating.image
                        ? '/aisling/aislingdone.webp'
                        : '/aisling/aislingthinking.webp'
                    "
                    type="image/webp"
                  />
                  <!-- Fallback image -->
                  <img
                    :src="
                      rating.image
                        ? '/aisling/aislingdone.webp'
                        : '/aisling/aislingthinking.webp'
                    "
                    alt="Aisling Rater result"
                    class="w-[281px] lg:w-full lg:max-w-[444px] lg:max-h-[518px] object-cover lg:relative"
                    width="444"
                  />
                </picture>
              </div>
              <div
                ref="resultBanner"
                class="lg:hidden block h-[328px] lg:h-full w-full lg:w-2/4 absolute bottom-0 left-0 right-0 lg:relative"
              >
                <picture>
                  <source
                    :srcset="
                      rating.image
                        ? '/aisling/aislingdone.avif'
                        : '/aisling/aislingthinking.avif'
                    "
                    type="image/avif"
                  />
                  <source
                    :srcset="
                      rating.image
                        ? '/aisling/aislingdone.webp'
                        : '/aisling/aislingthinking.webp'
                    "
                    type="image/webp"
                  />
                  <!-- Fallback image -->
                  <img
                    :srcset="
                      rating.image
                        ? '/aisling/aislingdone.webp'
                        : '/aisling/aislingthinking.webp'
                    "
                    alt="Aisling Rater result"
                    class="mx-auto h-full w-auto"
                    width="444"
                  />
                </picture>
              </div>

              <div
                class="hidden lg:flex relative rounded-[42px] max-w-[539px] max-h-[459px] h-full w-2/4 my-[33px]"
              >
                <img
                  class="rounded-[42px] max-w-[539px] max-h-[459px] h-auto w-full object-contain object-center"
                  :src="rating.image ? cdnFile(rating.image) : imageData.data"
                  alt="Uploaded image"
                />
                <div
                  v-if="loading"
                  class="absolute top-0 bottom-0 left-0 w-full flex flex-col items-center justify-center"
                >
                  <div
                    class="absolute w-full h-full bg-black opacity-50 rounded-[42px]"
                  ></div>
                  <div
                    v-if="loading"
                    class="relative w-[100px] h-[100px] rounded-full border-[8px] border-[#A15CFF] border-opacity-30"
                  >
                    <div
                      class="h-[100px] w-[100px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
                      style="
                        box-shadow:
                          0px 0px 30px 0px #cc47ff,
                          0px 0px 30px 0px #cc47ff inset;
                      "
                    >
                      <div
                        class="loader border-b-transparent w-full h-full"
                      ></div>
                    </div>
                    <p
                      class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-[35px]"
                    >
                      {{ loadingDisplayValue > 0 ? loadingDisplayValue : '' }}
                    </p>
                  </div>
                  <div v-if="loading" class="text-xl mt-[20px] z-20">
                    {{ isNaN(loadingDisplayValue) ? loadingDisplayValue : '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex lg:hidden relative rounded-[42px] mx-auto max-h-[241px] h-auto w-auto lg:w-full"
            ref="userImage"
          >
            <img
              class="rounded-[42px] max-w-[331px] max-h-[241px] h-auto w-full object-cover object-center"
              :src="rating.image ? cdnFile(rating.image) : imageData.data"
              alt="Uploaded image"
            />
            <div
              v-if="loading"
              class="absolute top-0 bottom-0 left-0 w-full flex flex-col items-center justify-center"
            >
              <div
                class="absolute w-full h-full bg-black opacity-50 rounded-[42px]"
              ></div>
              <div
                v-if="loading"
                class="relative w-[100px] h-[100px] rounded-full border-[8px] border-[#A15CFF] border-opacity-30"
              >
                <div
                  class="h-[100px] w-[100px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
                  style="
                    box-shadow:
                      0px 0px 30px 0px #cc47ff,
                      0px 0px 30px 0px #cc47ff inset;
                  "
                >
                  <div class="loader border-b-transparent w-full h-full"></div>
                </div>
                <p
                  class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-[35px]"
                >
                  {{ loadingDisplayValue > 0 ? loadingDisplayValue : '' }}
                </p>
              </div>

              <div
                v-if="loading"
                class="text-base leading-[23px] mt-[20px] z-20"
              >
                {{ isNaN(loadingDisplayValue) ? loadingDisplayValue : '' }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="rating && rating.description">
          <div
            class="bg-[#0a0d2280] relative lg:p-[32px] rounded-[35px] border border-[#141a3db3]"
            ref="result"
          >
            <div
              class="flex flex-col rounded-[25px] lg:border lg:border-[#141a3db3] bg-bottom bg-no-repeat bg-cover gap-[22px] lg:gap-[28px] px-[30px] pt-[30px] pb-[26px] lg:px-[67px] lg:pt-[43px] lg:pb-[23px]"
              :style="
                !user
                  ? 'filter: blur(4px) brightness(0.9); transform: scale(1.03)'
                  : ''
              "
            >
              <p class="text-base lg:text-xl text-justify">
                {{ rating.description }}
              </p>
              <img
                :src="soundOnIcon"
                alt="Sound on"
                width="40"
                height="34"
                class="w-[35px] h-auto lg:w-[40px] cursor-pointer"
                :class="{ 'cursor-not-allowed opacity-50': isPlaying }"
                @click="playAudio"
                :disabled="isPlaying"
              />
            </div>
            <button
              v-if="!user"
              class="bg-purple-500 text-white rounded-md py-2 px-3 text-center text-sm leading-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-2 items-center font-bold min-w-[105px]"
              @click="
                () => {
                  if (!user) {
                    openSignupRegister('rater_unlock')
                  } else {
                    openSubscribePopup('rater_unlock')
                  }
                }
              "
            >
              <img :src="unlock" /> Unlock For Free
            </button>
          </div>

          <form v-if="user">
            <label for="file-upload" @click.prevent="checkUserBeforeReupload">
              <div
                class="flex justify-center gap-[10px] cursor-pointer w-full py-[22px] rounded-[15px] border border-white border-opacity-15 bg-[#0A0D1E]"
                style="
                  box-shadow: 0px 0px 0px 1px #1e1a3d;
                  box-shadow: 0px 0px 10px 2px #13142e inset;
                "
              >
                <Eggplant :size="23" />
                <p class="text-base font-medium">Upload New Picture</p>
              </div>
            </label>
            <input
              id="file-upload"
              type="file"
              ref="fileInput"
              class="hidden"
              accept=".jpg, .jpeg, .png, .heic"
              @change="reupload"
            />
          </form>
          <div
            v-if="user && user.subscription"
            class="text-sm font-semibold text-[#ffffff99] leading-[1] flex gap-2 items-center justify-center"
          >
            <img :src="logoiconGrayRater" alt class="w-[22px]" />
            50
          </div>
        </template>
      </div>
      <FooterComponent></FooterComponent>
    </div>
  </div>
</template>
