<script setup>
import { ref, toRefs } from 'vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import x from '@/assets/icons/x-close.svg'

const props = defineProps({
  open: Boolean,
  unmount: {
    type: Boolean,
    default: true,
  },
  mobile: {
    type: Boolean,
    default: true,
  },
  closeButton: {
    type: Boolean,
    default: true,
  },
  prevButton: {
    type: Boolean,
    default: false,
  },
  overlayExit: {
    type: Boolean,
    default: true,
  },
  backStyle: {
    type: Object,
    default: {},
  },
  popupStyle: {
    type: String,
    default: '',
  },
  animate: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:open', 'prev'])

const { open, unmount, closeButton, prevButton, overlayExit, animate } =
  toRefs(props)

function close() {
  emit('update:open', false)
}

function outerClick() {
  if (overlayExit.value) close()
}
</script>

<template>
  <TransitionRoot as="template" :show="open" :unmount="unmount">
    <Dialog
      as="div"
      :unmount="unmount"
      class="relative z-[60]"
      @close="outerClick"
    >
      <TransitionChild
        class="backdrop-blur-sm"
        as="template"
        :unmount="unmount"
        :enter="animate ? 'ease-out duration-300' : 'duration-0'"
        :enter-from="animate ? 'opacity-0' : 'opacity-100'"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[60] w-screen overflow-y-auto">
        <div
          class="flex h-full min-h-full justify-center text-center md:p-0 bg-none"
          :class="mobile ? 'items-end sm:items-center' : 'items-center'"
          :style="backStyle"
        >
          <TransitionChild
            as="template"
            :unmount="unmount"
            :enter="animate ? 'ease-in-out duration-500' : 'duration-0'"
            :enter-from="
              animate
                ? 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50'
                : 'opacity-100'
            "
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
          >
            <DialogPanel
              :unmount="unmount"
              class="relative transform bg-[#070917] rounded-[15px] lg:rounded-[35px] text-left shadow-xl transition-all lg:py-[38px] my-auto sm:max-h-[95vh] border border-[#141A3D] border-opacity-70 [&::-webkit-scrollbar]:hidden"
              :class="popupStyle"
            >
              <div v-if="prevButton" class="absolute left-0 top-0 pl-4 pt-4">
                <button
                  type="button"
                  class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  @click="emit('prev')"
                >
                  <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div
                v-if="closeButton"
                class="absolute right-0 top-0 pr-2 pt-2 lg:pr-4 lg:pt-4"
              >
                <button
                  type="button"
                  class="flex justify-center items-center bg-[#151833] rounded-[11px] w-[37px] h-[37px] focus:outline-none"
                  @click="close"
                >
                  <img :src="x" class="w-5 h-5" />
                </button>
              </div>
              <div class="max-h-full [&::-webkit-scrollbar]:hidden">
                <slot></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
