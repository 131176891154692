<script setup>
import {
  ref,
  inject,
  computed,
  toRefs,
  watch,
  onMounted,
  onUnmounted,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import googleAuth from '@/services/google-auth'
import twitterAuth from '@/services/twitter-auth'
import SmsVerificationDialog from './SmsVerificationDialog.vue'
import Confirm from '@/components/Confirm.vue'
import checkmark from '@/assets/icons/checkmark.svg'
import ButtonComponent from './ButtonComponent.vue'
import { useMixpanel } from '@/composables/mixpanel'
import ErrorAlert from '@/components/ErrorAlert.vue'
import IntlTelInput from 'intl-tel-input/vueWithUtils'
import safeGenerateRecaptchaToken from '@/helpers/safeGenerateRecaptchaToken.js'
import 'intl-tel-input/styles'

const mixpanel = useMixpanel()

mixpanel.track('register_modal_shown')

const $axios = inject('axios')
const $store = useStore()
const $route = useRoute()
const $router = useRouter()

const email = ref($store.state.email)
const password = ref($store.state.password)

const tempGender = computed(() => $store.state.tempGender)

watch(email, (newEmail) => {
  $store.commit('SET_EMAIL', newEmail)
})

watch(password, (newPassword) => {
  $store.commit('SET_PASSWORD', newPassword)
})

onMounted(() => {
  const errorMessage = $route.query.errorMessage
  if (!errorMessage) return
  error.value = errorMessage
  $router.replace({ query: {} })
})

onUnmounted(() => {
  $store.commit('SET_EMAIL', null)
  $store.commit('SET_PASSWORD', null)
})

const user = computed(() => $store.state.user)

const newPhoneNumber = ref('')
const name = ref('')
const acceptPolicies = ref(false)
const emailOptIn = ref(true)
const acceptRequire = ref(false)
const phoneNumberModalOpen = ref(false)
const showIntelTelInput = ref(false)
const error = ref(null)

const analytics = computed(() => $store.state.analytics)

function generateCaptchaToken() {
  // This is hardcoded instead of using `getRecaptchaExpectedAction` because we can't get
  // the expected action from the URL for Google & Twitter auth, since the request is sent
  // to their servers instead of ours
  return safeGenerateRecaptchaToken('signup')
}

const $toast = useToast()

const props = defineProps({
  withAcceptPolicies: Boolean,
  ctaText: String,
  modelIds: {
    type: Array,
    default: () => [],
  },
  redirectPath: String,
  validationOpen: Boolean,
})

const { withAcceptPolicies, ctaText, modelIds, redirectPath } = toRefs(props)

function checkAcceptPolicies() {
  if (withAcceptPolicies.value && !acceptPolicies.value) {
    acceptRequire.value = true
    error.value = 'Please confirm you accept our policies'
    return false
  }
  acceptRequire.value = false
  return true
}

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const emit = defineEmits(['registered', 'update:validationOpen'])

const utmParams = computed(() => $store.state.utm)

async function google() {
  error.value = null

  // Mixpanel will automatically use the Beacon API on redirect and preserve the correct event order
  mixpanel.track('register_click_google')

  const captchaToken = await generateCaptchaToken()

  window.location.href = googleAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: redirectPath.value,
  })
}

async function twitter() {
  error.value = null

  // Mixpanel will automatically use the Beacon API on redirect and preserve the correct event order
  mixpanel.track('register_click_twitter')

  const captchaToken = await generateCaptchaToken()

  window.location.href = twitterAuth.getLoginUrl({
    ...analytics.value,
    captchaToken,
    modelIds: modelIds.value,
    emailOptIn: emailOptIn.value,
    ...utmParams.value,
    redirectPath: props.redirectPath,
  })
}

async function register() {
  error.value = null
  if (!checkAcceptPolicies()) return

  mixpanel.track('register_click_signup')

  const loader = $loading.show()

  const captchaToken = await generateCaptchaToken()

  console.log('tempGender', tempGender?.value)

  try {
    const user = await $axios({
      method: 'post',
      url: '/auth/signup',
      data: {
        email: email.value,
        name: name.value.split(' ')[0],
        password: password.value,
        modelIds: modelIds.value,
        emailOptIn: emailOptIn.value,
        ...(tempGender?.value && { gender: tempGender.value }),
      },
      params: {
        ...utmParams.value,
      },
      headers: {
        'Captcha-Token': captchaToken,
      },
    }).then((res) => res.data)
    await $store.commit('SET_USER', user)
    $store.commit('SET_FREE_GENERATED_IMAGES', user.freeGeneratedImages)
    await $store.commit('SET_PREMADE_MODEL', null)

    if (user.smsVerificationRequired) {
      loader.hide()
      return emit('update:validationOpen', true)
    }

    emit('registered')
  } catch (e) {
    console.log(e)
    const message = e?.response?.data?.message
    error.value = message || e.response.status
  }
  loader.hide()
}

async function changePhoneNumber() {
  const loader = $loading.show()

  try {
    const { message } = await $axios({
      method: 'post',
      url: '/sms-verification/change-number',
      data: {
        phoneNumber: newPhoneNumber.value,
      },
    }).then((res) => res.data)

    mixpanel.track('sms_verification_change_number')

    $store.commit('SET_USER', {
      ...$store.state.user,
      phoneNumber: newPhoneNumber.value,
    })
    newPhoneNumber.value = ''
    updatePhoneNumberModalOpen(false)
    $toast.success(message)
  } catch (e) {
    const message = e?.response?.data?.message
    error.value = message || 'Unable to perform action. Please try again later.'
    $toast.error(error.value)
  } finally {
    loader.hide()
  }
}

const updatePhoneNumberModalOpen = (val) => {
  phoneNumberModalOpen.value = val

  if (val) {
    setTimeout(() => {
      showIntelTelInput.value = true
    }, 0)
  } else {
    setTimeout(() => {
      showIntelTelInput.value = false
    }, 300)
  }
}

const finishRegister = () => {
  emit('registered')
}

const validateModalShown = computed(() => {
  return props.validationOpen || Boolean(user.value)
})

watch(
  validateModalShown,
  (val) => {
    if (val) emit('update:validationOpen', val)
    else {
      ;(name.value = ''), (email.value = ''), (password.value = '')
      acceptPolicies.value = false
    }
  },
  { immediate: true },
)

const brandName = import.meta.env.VITE_BRAND_NAME

const handleNumberChange = (newNumber) => {
  newPhoneNumber.value = newNumber
}

function validateName(event) {
  name.value = event.target.value.replace(/[^a-zA-Z ]/g, '')
}
</script>

<template>
  <div class="mt-[25px] lg:mt-[30px]" v-if="!props.validationOpen">
    <div>
      <form
        action="#"
        method="POST"
        class="space-y-[20px] lg:space-y-[27px]"
        @submit.prevent="register"
        @click="error = null"
      >
        <div>
          <label
            for="first-name"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >First Name</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="first-name"
              v-model="name"
              @input="validateName"
              name="first-name"
              type="text"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label
            for="email"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >Email Address</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div>
          <label
            for="password"
            class="text-white text-opacity-80 text-sm lg:text-base leading-[1]"
            >Password</label
          >
          <div class="mt-[5px] lg:mt-[10px]">
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
            />
          </div>
        </div>

        <div class="flex flex-col gap-[37px] lg:gap-5">
          <div
            v-if="withAcceptPolicies"
            class="flex items-start cursor-pointer"
            @click="acceptPolicies = !acceptPolicies"
          >
            <div class="relative">
              <input
                @click="error = null"
                id="tos"
                v-model="acceptPolicies"
                aria-describedby="tos-description"
                aria-label="tos"
                name="tos"
                type="checkbox"
                class="h-[22px] w-[22px] rounded-[5px] focus:border-0 focus:outline-0 focus:ring-0 focus-visible:outline-none focus-visible:border-none hover:cursor-pointer"
                :class="
                  (acceptRequire && 'ring-2 ring-red-600 checked:ring-0') || ''
                "
                style="
                  background: radial-gradient(
                    255.14% 174.74% at 38.76% 155.71%,
                    #cc47ff 0%,
                    #9a5cff 100%
                  );
                "
              />
              <img
                v-if="acceptPolicies"
                class="absolute w-[11px] h-auto top-[50%] left-[50%] -translate-x-1/2 -translate-y-[25%]"
                :src="checkmark"
                alt="checkmark"
              />
            </div>
            <div
              @click="error = null"
              class="ml-[10px] text-sm lg:text-base leading-6"
            >
              <span id="comments-description" class="text-[#f4f7fb]"
                >I have read and accept the
                <router-link
                  class="text-[#cc47ff]"
                  to="/terms-of-service"
                  @click.stop
                  >Terms of Service</router-link
                >
                and
                <router-link
                  class="text-[#cc47ff]"
                  to="/privacy-policy"
                  @click.stop
                  >Privacy Policy</router-link
                >.</span
              >
            </div>
          </div>

          <!-- Temporarily remvoe the email opt in -->
          <!-- <div
            class="flex items-start cursor-pointer"
            @click="emailOptIn = !emailOptIn"
          >
            <div class="flex h-6 items-center">
              <div class="relative">
                <input
                  @click="error = null"
                  id="opt-in"
                  v-model="emailOptIn"
                  aria-describedby="opt-in-description"
                  name="opt-in"
                  type="checkbox"
                  class="h-[22px] w-[22px] rounded-[5px] focus:border-0 focus:outline-0 focus:ring-0 focus-visible:outline-none focus-visible:border-none hover:cursor-pointer"
                  :class="
                    (acceptRequire && 'ring-2 ring-red-600 checked:ring-0') ||
                    ''
                  "
                  style="
                    background: radial-gradient(
                      255.14% 174.74% at 38.76% 155.71%,
                      #cc47ff 0%,
                      #9a5cff 100%
                    );
                  "
                />
                <img
                  v-if="emailOptIn"
                  class="absolute w-[11px] h-auto top-[50%] left-[50%] -translate-x-1/2 -translate-y-[25%]"
                  :src="checkmark"
                />
              </div>
            </div>
            <div @click="error = null" class="ml-[10px] text-sm leading-6">
              <span id="comments-description" class="text-gray-300"
                >I want to receive discreet marketing emails from
                {{ brandName }}.</span
              >
            </div>
          </div> -->
        </div>
        <div>
          <ButtonComponent
            type="submit"
            class="w-full py-[14px] rounded-[15px]"
          >
            {{ ctaText || 'Sign up' }}
          </ButtonComponent>
        </div>
        <ErrorAlert v-if="error" :errorText="error" />
      </form>
    </div>

    <div>
      <div
        class="my-[15px] lg:my-[30px] text-[#B1B5DB] text-sm flex items-center gap-2 lg:gap-4"
      >
        <div
          class="h-[1px] flex-1"
          style="
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0) 100%
            );
          "
        ></div>
        <p>Or continue with</p>
        <div
          class="h-[1px] flex-1"
          style="
            background: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.3) 100%
            );
          "
        ></div>
      </div>
      <div class="flex gap-[9px]">
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="google"
        >
          <svg
            id="Capa_1"
            class="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 210 210"
            xml:space="preserve"
          >
            <title>Google Logo</title>
            <path
              d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40 c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105 S0,162.897,0,105z"
            />
          </svg>
          <span class="leading-6">Google</span>
        </a>
        <a
          href="#"
          class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
          @click.prevent="twitter"
        >
          <svg
            class="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 300 300"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
            />
          </svg>
          <span class="text-sm font-semibold leading-6">Twitter</span>
        </a>
      </div>
      <div class="text-xs md:text-sm text-[#f4f7fb] text-center mt-4 pb-2">
        By using social media sign up, you confirm that you have read and accept
        the
        <router-link class="text-[#CC47FF]" to="/terms-of-service" @click.stop
          >Terms of Service</router-link
        >
        and
        <router-link class="text-[#CC47FF]" to="/privacy-policy" @click.stop
          >Privacy Policy</router-link
        >.
      </div>
    </div>
  </div>
  <SmsVerificationDialog
    v-else
    @finish-register="finishRegister"
    @open-phone-number-dialog="updatePhoneNumberModalOpen"
  />
  <Confirm
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[124px]"
    :open="phoneNumberModalOpen"
    :showDefault="false"
    type="confirm"
    @update:open="updatePhoneNumberModalOpen"
  >
    <form @submit.prevent="changePhoneNumber" class="m-1 bg-none">
      <h1 class="text-center">Change your phone number</h1>
      <IntlTelInput
        v-if="showIntelTelInput"
        @changeNumber="handleNumberChange"
        :options="{
          initialCountry: user.countryCode,
          containerClass: 'w-full mt-4 mb-6',
        }"
      />
      <ButtonComponent type="submit" class="py-3 px-6 w-full mt-2">
        Confirm
      </ButtonComponent>
    </form>
  </Confirm>
</template>

<style scoped>
input:not([type='checkbox']):focus {
  box-shadow: 0px 0px 0px 4px #cc47ff33;
}
</style>
