<script setup>
import logoicon from '@/assets/logoicon.svg'
import AppHeader from '@/components/AppHeader.vue'
import { useMixpanel } from '@/composables/mixpanel'
import cdnFile from '@/helpers/cdnFile.js'
import {
  sidebarOpen,
  touchend,
  touchmove,
  touchstart,
} from '@/helpers/navigationMenu.js'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import premadeModels from 'common/lib/premade.js'
import { v4 as uuidv4 } from 'uuid'
import {
  computed,
  inject,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useLoading } from 'vue-loading-overlay'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import gifts from '@/configs/gifts.js'
import trialConfig from '../../../../common/configs/trial'
import * as stepsConfig from '@/configs/steps'
import infoCircle from '@/assets/icons/info-circle.svg'
import volumeX from '@/assets/icons/volume-x.svg'
import Speaker from '@/components/icons/Speaker.vue'
import microphone from '@/assets/icons/microphone.svg'
import imageUp from '@/assets/icons/image-up.svg'
import gift from '@/assets/icons/gift.svg'
import send from '@/assets/icons/send.svg'
import camera from '@/assets/icons/camera.svg'
import xClose from '@/assets/icons/x-close.svg'
import chevronLeft from '@/assets/icons/chevron-left.svg'
import play from '@/assets/icons/play.svg'
import pauseCircle from '@/assets/icons/pause-circle.svg'
import pauseCircleOutline from '@/assets/icons/pause-circle-outline.svg'
import AudioPlayer from '@/components/AudioPlayer.vue'
import GenderPopup from '@/components/GenderPopup.vue'
import ImagePopup from '@/components/ImagePopup.vue'
import formatMessage from '@/helpers/formatMessage'
import { useHead } from '@unhead/vue'
import { useUpdateQuery } from '@/composables/useUpdateQuery'
import ButtonComponent from '../../components/ButtonComponent.vue'
import LunaAmount from '../../components/LunaAmount.vue'
import { usePaymentNotifier } from '@/composables/usePaymentNotifier.js'
import ImageMessageItem from '@/views/Chat/components/ImageMessageItem.vue'
import MessageItem from '@/views/Chat/components/MessageItem.vue'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Chat`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

const model = computed(() => $store.getters.model)

const premadeModelLore = computed(() => {
  const premadeModel = premadeModels.find(
    (m) => m.model.name === model.value?.name && model.value.premadeId,
  )
  return premadeModel?.lore
})

const endRef = ref(null)
const isRecording = ref(false)
const mediaRecorder = ref(null)
const audioChunks = ref([])
const sendingAudioPlayer = ref(null)
const isPlayingSendingAudio = ref(false)

const sendingAudio = ref(null)
const cancelAudioFlag = ref(false)
const sendAudioFlag = ref(false)
const waves = ref(0)

const startingTime = ref(0)
const recordingIntervalID = ref(null)
const recordingWavesInterval = ref(null)
const recordingDuration = ref(0)
const playingDuration = ref(0)

const mobileButtonPanel = ref(false)
const modelInfoOpened = ref(false)
const modelInfo = ref(null)
const modelInfoContent = ref(null)
watch(modelInfoOpened, (value) => {
  if (value) {
    modelInfo.value.classList.remove('-right-full')
    modelInfo.value.classList.add('right-0')
  } else {
    setTimeout(() => {
      modelInfoContent.value.scrollTop = 0
      modelInfo.value.classList.remove('right-0')
      modelInfo.value.classList.add('-right-full')
    }, 300)
  }
})

const waveSizes = [14, 40, 14, 26, 14]
const maxWaves = 30
const maxWavesMobile = 15

const formattedDuration = (duration) => {
  const sec = Math.floor(duration / 1000) % 60
  const min = Math.floor(Math.floor(duration / 1000) / 60)
  return `${min >= 10 ? min : `0${min}`}:${sec >= 10 ? sec : `0${sec}`}`
}

function stopPlayingSendingAudio() {
  if (sendingAudioPlayer.value) {
    sendingAudioPlayer.value.pause()
    sendingAudioPlayer.value.currentTime = 0
  }
  playingDuration.value = 0
  isPlayingSendingAudio.value = false
}

const voiceRecordingStream = ref(null)

const preferredAudioMimeTypes = [
  'audio/webm;codecs=opus',
  'audio/mp4',
  'audio/ogg;codecs=opus',
]

function pickAudioMimeType() {
  for (let i = 0; i < preferredAudioMimeTypes.length; i++) {
    if (MediaRecorder.isTypeSupported(preferredAudioMimeTypes[i]))
      return preferredAudioMimeTypes[i]
  }
  throw new Error(`No audio mime type supported`)
}

async function startRecording() {
  if (!user.value) {
    openSubscribePopup(false)
  }

  if (
    (!user.value.subscription && user.value.cardLast4) ||
    hasExpiredTrial.value
  ) {
    openSubscribePopup()
    return
  }

  stopPlayingSendingAudio()
  if (!voiceRecordingStream.value)
    voiceRecordingStream.value = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    })
  const newMediaRecorder = new MediaRecorder(voiceRecordingStream.value, {
    mimeType: pickAudioMimeType(),
  })
  newMediaRecorder.ondataavailable = (event) => {
    recordingDuration.value = new Date().getTime() - startingTime.value
    if (event.data.size > 0) audioChunks.value.push(event.data)
  }
  newMediaRecorder.onstop = async () => {
    isRecording.value = false

    const blob = new Blob(audioChunks.value, {
      type: newMediaRecorder.mimeType,
    })
    if (!cancelAudioFlag.value) {
      recordingDuration.value = new Date().getTime() - startingTime.value
      playingDuration.value = 0
      const voiceData = {}
      ;(voiceData.fileType = blob.type),
        (voiceData.voiceDuration = recordingDuration.value)
      const base64Data = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = function () {
          resolve(reader.result)
        }
        reader.readAsDataURL(blob)
      })
      voiceData.data = base64Data
      sendingAudio.value = voiceData
      if (sendAudioFlag.value) {
        sendAudioFlag.value = false
        sendForm()
      }
      audioChunks.value = []
    } else {
      audioChunks.value = []
      cancelAudioFlag.value = false
    }
  }
  newMediaRecorder.start()
  startingTime.value = new Date().getTime()

  recordingIntervalID.value = setInterval(() => {
    if (isRecording.value) {
      recordingDuration.value = new Date().getTime() - startingTime.value
      if (recordingDuration.value >= 60000) {
        stopRecording()
      }
    }
  }, 100)

  mediaRecorder.value = newMediaRecorder
  isRecording.value = true

  mixpanel.track('chat_recording_start', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })

  recordingWavesInterval.value = setInterval(addWaves, 250)
}

function stopRecording(emitEvent = false) {
  clearInterval(recordingWavesInterval.value)
  clearInterval(recordingIntervalID.value)
  stopPlayingSendingAudio()
  if (mediaRecorder.value) mediaRecorder.value.stop()
  else {
    cancelAudioFlag.value = false
    sendAudioFlag.value = false
  }
  mediaRecorder.value = null
  if (emitEvent) {
    mixpanel.track('chat_recording_stop', {
      chat_session_id: chatSessionId.value,
      chat_time_elapsed: chatSessionTimeElapsed(),
      model_name: model.value?.name,
      model_id: model.value?.id,
      model_metadata: model.value,
    })
  }
}

function stopStream() {
  stopRecording()
  if (voiceRecordingStream.value) {
    voiceRecordingStream.value.getTracks().forEach((track) => track.stop())
    voiceRecordingStream.value = null
  }
}

// const sidebarOpen = ref(false)
const $store = useStore()

const $axios = inject('axios')

const $router = useRouter()
const $route = useRoute()

const user = computed(() => $store.state.user)
const tempGender = computed(() => $store.state.tempGender)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const hasSubscription = computed(() => user.value?.subscription)
const hasExpiredTrial = computed(
  () =>
    (!user.value?.subscription &&
      ($store.state.messages.length >= trialConfig.messageCap ||
        $store.state.user.totalMessageCount >= trialConfig.globalMessageCap)) ||
    $store.state.user.trialExpired,
)

const mixpanel = useMixpanel()

const freeGeneratedImages = computed(() => $store.state.freeGeneratedImages)

$store.commit('SET_CHAT_SESSION_ID', uuidv4())

const chatSessionId = computed(() => $store.state.chatSessionId)
let chatSessionTimeElapsed = createTimeElapsedCounter()

// let timeElapsedVC = () => {
//   if (document.visibilityState === 'hidden') {
//     mixpanel.track(
//       'chat_session_end',
//       {
//         chat_session_id: chatSessionId.value,
//         chat_time_elapsed: chatSessionTimeElapsed(),
//         model_name: model.value?.name,
//         model_id: model.value?.id,
//         model_metadata: model.value,
//       },
//       { transport: 'sendBeacon' },
//     )
//   } else {
//     $store.commit('SET_CHAT_SESSION_ID', uuidv4())
//     chatSessionTimeElapsed = createTimeElapsedCounter()
//     mixpanel.track(
//       'chat_page_visit',
//       {
//         chat_session_id: chatSessionId.value,
//         chat_time_elapsed: chatSessionTimeElapsed(),
//         model_name: model.value?.name,
//         model_id: model.value?.id,
//         model_metadata: model.value,
//       },
//       { source: 'resume' },
//     )
//   }
// }

// document.addEventListener('visibilitychange', timeElapsedVC)

// onBeforeUnmount(() => {
//   document.removeEventListener('visibilitychange', timeElapsedVC)
//   mixpanel.track('chat_session_end', {
//     chat_session_id: chatSessionId.value,
//     chat_time_elapsed: chatSessionTimeElapsed(),
//     model_name: model.value?.name,
//     model_id: model.value?.id,
//     model_metadata: model.value,
//   })
// })

mixpanel.track(
  'chat_page_visit',
  {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  },
  { source: 'previous' },
)

const imageInput = ref(null)
const sendingImage = ref(null)

function discardRecording() {
  waves.value = 0
  cancelAudioFlag.value = true
  sendingAudio.value = null
  stopRecording()

  mixpanel.track('chat_recording_discard', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })
}

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
})

const selectedImageMessage = ref(null)
const imagePopupOpen = ref(false)

function openImagePopup(message) {
  selectedImageMessage.value = message
  imagePopupOpen.value = true
}

function imagePopupUpdated(val) {
  imagePopupOpen.value = val
}

const giftPanelOpen = ref(false)

function openGiftPanel() {
  if (giftPanelOpen.value) {
    giftPanelOpen.value = false
  } else {
    giftPanelOpen.value = true

    mixpanel.track('chat_open_gift_panel', {
      chat_session_id: chatSessionId.value,
      chat_time_elapsed: chatSessionTimeElapsed(),
      model_name: model.value?.name,
      model_id: model.value?.id,
      model_metadata: model.value,
    })
  }
}

const toggleGiftPanelButton = ref(null)

function closeGiftPanel() {
  if (
    toggleGiftPanelButton.value &&
    !toggleGiftPanelButton.value.contains(event.target)
  ) {
    giftPanelOpen.value = false
  }
}

const giftPanelOpenUpdated = (val) => {
  giftPanelOpen.value = val
}

function openSubscriptionExpiredPopup() {
  $store.commit('SET_SUBSCRIBE_EXPIRED_POPUP', {
    open: true,
    text: 'continue chatting',
  })
}

function openSubscribePopup(eventType = 'chat_messages', limitHitEvent = true) {
  const event = limitHitEvent === true ? eventType : limitHitEvent

  if (!user.value) {
    $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
      open: true,
      freeLimitHit: event,
    })
    return
  } else if (!user.value.subscription && user.value.cardLast4) {
    openSubscriptionExpiredPopup()
  }

  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: true,
    ...((messages.value.length >= trialConfig.messageCap ||
      user.value.totalMessageCount >= trialConfig.globalMessageCap ||
      hasExpiredTrial.value) && {
      text: 'continue chatting',
    }),
    freeLimitHit: event,
  })
}

const genderPopupOpen = ref(!user.value?.gender)
const genderPopupOpenUpdated = (val) => {
  genderPopupOpen.value = val
}

const typing = computed(() => $store.state.typing)
const setTyping = (val) => $store.commit('SET_TYPING', val)

const newMessage = ref('')

useUpdateQuery({
  variables: {
    modelId: computed(() => model.value?.id || model.value?.premadeId),
    message: newMessage,
  },
  onUpdate() {
    $store.commit('UPDATE_LAST_APP_PAGE')
  },
  onLoad(queryVariables) {
    if (queryVariables.modelId)
      $store.commit('SET_SELECTED_MODEL', queryVariables.modelId)
    if (queryVariables.message) newMessage.value = queryVariables.message
  },
})

const luna = computed(() => $store.state.user?.luna)

const voiceMessageToggle = computed(() => $store.state.voiceMessageToggle)

const messages = computed(() => {
  const formattedMessages = $store.state.messages.map((message) => ({
    ...message,
    content: formatMessage(message.content),
  }))

  const transformed = formattedMessages.reduce(
    (acc, { role, content, id, type, ...other }) => {
      const lastEntry = acc[acc.length - 1]
      if (lastEntry && lastEntry.role === role && type === 'text') {
        lastEntry.contents.push({ id, content })
      } else {
        acc.push({
          role,
          contents: [{ id, content }],
          id,
          content,
          type,
          ...other,
        })
      }
      return acc
    },
    [],
  )

  if (typing.value) {
    const typeMessage = {
      role: 'assistant',
      contents: [{ id: '__typing__', content: '__typing__' }],
    }
    if (transformed.length == 1)
      transformed[transformed.length - 1] = typeMessage
    else transformed.push(typeMessage)
  }

  return transformed.reverse()
})

const inputTextarea = ref(null)

const chatContainer = ref(null)

function scrollChatContainer() {
  nextTick(() => {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight
  })
}

const responseMessageType = computed(() =>
  !voiceMessageToggle.value ? 'text' : 'voice',
)
const selectedGiftIndex = ref(0)

async function sendForm(v) {
  if (!user.value) {
    if (messages.value.length >= trialConfig.noAuthMessageCap) {
      openSubscribePopup()
      return
    }
  }

  if (typing.value) return
  stopPlayingSendingAudio()
  waves.value = 0
  closeSendImageReminder()

  if (user.value) {
    if (
      (!user.value.subscription && user.value.cardLast4) ||
      hasExpiredTrial.value
    ) {
      openSubscriptionExpiredPopup()
      return
    }
  }

  if (isRecording.value) {
    waves.value = 0
    sendAudioFlag.value = true
    stopRecording(true)
  } else {
    nextTick(() => {
      if (sendingAudio.value) {
        sendMessage('voice')
      } else {
        sendMessage('text')
      }
    })
  }
}

async function buyGift(giftIndex) {
  selectedGiftIndex.value = giftIndex
  sendMessage('gift')
}

async function sendMessage(sendingType) {
  if (typing.value) return
  if (
    sendingType != 'gift' &&
    !sendingImage.value &&
    !sendingAudio.value &&
    !newMessage.value.trim()
  )
    return

  const giftObject = gifts[selectedGiftIndex.value]
  let messageValue = newMessage.value.trim()

  if (sendingType === 'gift') {
    if (giftObject.price > user.value.luna) {
      $store.commit('SET_NO_BALANCE_POPUP', {
        open: true,
      })
      return
    }
    $store.commit('CHANGE_LUNA', -giftObject.price)
    messageValue = `gift`
  } else if (sendingImage.value) {
    if (user.value.luna < 20) {
      $store.commit('SET_NO_BALANCE_POPUP', {
        open: true,
      })
      return
    }
    $store.commit('CHANGE_LUNA', -20)
  }

  if (
    user.value &&
    !user.value.subscription &&
    (sendingAudio.value || sendingType === 'voice')
  ) {
    openSubscribePopup()
    return
  }

  let image
  let voice
  let gift
  gift = sendingType === 'gift' ? giftObject.id : null

  closeGiftPanel()

  if (messages.value.length === 3) {
    openSendImageReminder('chat_messages')
  }

  const fileData = {}

  if (sendingImage.value) {
    fileData.imageFileType = sendingImage.value.fileType
    image = sendingImage.value ? sendingImage.value.data : null
  }

  if (sendingAudio.value) {
    fileData.voiceFileType = sendingAudio.value.fileType
    fileData.voiceDuration = sendingAudio.value.voiceDuration
    voice = sendingAudio.value ? sendingAudio.value.data : null
  }

  newMessage.value = ''
  sendingImage.value = null
  sendingAudio.value = null
  resetTextareaHeight()

  const formattedNewMessage = {
    type: sendingType,
    role: 'user',
    content: messageValue,
    image,
    voice,
    gift,
    ...fileData,
  }
  $store.commit('ADD_MESSAGES', [formattedNewMessage])
  scrollChatContainer()

  setTyping(true)
  scrollChatContainer()

  const startTime = new Date()

  let response
  try {
    response = await $axios.post('/user/chat', {
      newMessage: formattedNewMessage,
      responseMessageType: responseMessageType.value,
      modelId: model.value.id,
      chatSessionId: chatSessionId.value,
      chatSessionTimeElapsed: chatSessionTimeElapsed(),
      ...(!user?.value && { tempGender: tempGender.value }),
    })
  } catch (e) {
    const data = e.response.data
    const message = data.message
    const deleteMessage = data.deleteMessage
    const serverResponseMessageType = data.responseMessageType
    if (deleteMessage) {
      $store.commit('DELETE_RECENT_MESSAGES', 1)
    }
    if (serverResponseMessageType === 'text') {
      $store.commit('DISABLE_VOICE_MESSAGE')
    }
    if (sendingType === 'gift') $store.commit('CHANGE_LUNA', giftObject.price)
    if (sendingImage.value) $store.commit('CHANGE_LUNA', 20)
    if (e.response.status === 403) {
      openSubscribePopup()
    }
    if (e.response.status === 402) {
      $store.commit('SET_NO_BALANCE_POPUP', {
        open: true,
      })
      setTyping(false)
      return
    }
    // Remove last message from state if user is warned for ageDetection
    if (e.response.status === 451) {
      $store.commit('DELETE_RECENT_MESSAGES', 1)
    }
    setTyping(false)
    return
  }

  if (response.data.newMessages[0].voice) {
    $store.commit('CHANGE_LUNA', -1)
  }

  const minMessageTime = 3000
  const maxMessageTime = 7000

  const minMessageLength = 30
  const maxMessageLength = 150

  const timePerCharacter =
    (maxMessageTime - minMessageTime) / (maxMessageLength - minMessageLength)
  const neededTime =
    minMessageTime +
    (response.data.newMessages[0].content.length - minMessageLength) *
      timePerCharacter

  const elapsedSeconds = new Date() - startTime

  setTimeout(() => {
    response.data.newMessages = response.data.newMessages.map((message) => ({
      ...message,
      newMessage: true,
    }))

    $store.commit('MAKE_MODEL_FIRST', {
      modelId: model.value.id,
      message: response.data.newMessages[0],
    })

    $store.commit('ADD_MESSAGES', response.data.newMessages)
    scrollChatContainer()
    setTyping(false)
  }, neededTime - elapsedSeconds)
}

function dvcListener() {
  if (document.visibilityState === 'hidden') {
    stopStream()
  }
}

function stopImageRightClick(event) {
  if (event.target.nodeName === 'IMG') {
    event.preventDefault()
  }
}

onMounted(async () => {
  scrollChatContainer()

  usePaymentNotifier(openSubscribePopup, openBuyLuna)

  window.addEventListener('blur', stopStream)
  // window.addEventListener('beforeunload', stopStream)
  // window.addEventListener('unload', stopStream)
  window.addEventListener('pagehide', stopStream)
  document.addEventListener('visibilitychange', dvcListener)
  window.addEventListener('contextmenu', stopImageRightClick)
})

onUnmounted(() => {
  sidebarOpen.value = false
  $store.commit('SET_SELECTED_MODEL', null)
  stopStream()

  clearInterval(recordingIntervalID.value)

  window.removeEventListener('blur', stopStream)
  // window.removeEventListener('beforeunload', stopStream)
  // window.removeEventListener('unload', stopStream)
  window.removeEventListener('pagehide', stopStream)
  document.removeEventListener('visibilitychange', dvcListener)
  window.removeEventListener('contextmenu', stopImageRightClick)
})

const messagesFullyLoaded = computed(
  () =>
    ($store.state.messages?.length && !$store.state.hasMore) ||
    $store.state.premadeModel,
)

async function loadOlderMessages() {
  const messageCount = $store.state.messages.length

  if (!messageCount) return

  const oldScrollHeight = chatContainer.value.scrollHeight

  const { messages, hasMore } = await $axios
    .get('/user/chat', {
      params: {
        limit: 20,
        offset: messageCount,
        modelId: model.value.id,
      },
    })
    .then((response) => response.data)

  $store.commit('SET_HAS_MORE', hasMore)
  $store.commit('ADD_OLDER_MESSAGES', messages)

  mixpanel.track('chat_load_earlier_messages', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })

  // nextTick(() => {
  //   const newScrollHeight = chatContainer.value.scrollHeight;
  //   chatContainer.value.scrollTop += (newScrollHeight - oldScrollHeight)
  // })
}

const infiniteLoading = ref(false)

async function infinteMessageLoad() {
  if (infiniteLoading.value || messagesFullyLoaded.value) return
  const totalHeight = chatContainer.value.scrollHeight
  const scrolledHeight =
    Math.abs(chatContainer.value.scrollTop) + chatContainer.value.clientHeight
  const scrollPercentage = (scrolledHeight / totalHeight) * 100
  if (scrollPercentage >= 50) {
    // Trigger the method to fetch more data
    infiniteLoading.value = true
    await loadOlderMessages()
    infiniteLoading.value = false
  }
}

function openBuyLuna() {
  if (!user.value) {
    $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', { open: true })
    return
  }

  if (!user.value.subscription) {
    openSubscribePopup()
    return
  }

  $router.push('/buy-luna')
}

function audioMessageLink(voice) {
  if (voice && voice.startsWith('blob:')) {
    return voice
  }

  return cdnFile(voice, true)
}

function adjustTextarea() {
  // handling textarea auto resizing trick
  inputTextarea.value.style.height = `auto`
  inputTextarea.value.style.height = `${inputTextarea.value.scrollHeight}px`
}

function resetTextareaHeight() {
  inputTextarea.value.style.height = `50px`
}
function giftImage(id) {
  return gifts[gifts.findIndex((item) => item.id == id)]?.image
}

async function imageSelect(e) {
  if (e.target.files[0]) {
    const file = e.target.files[0]

    const imageData = {}
    imageData.fileType = file.type

    await new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        imageData.data = reader.result
        resolve()
      }
    })

    if (file.type === 'image/heic') {
      const loader = $loading.show()
      await $axios
        .post('/image/convert-heic', { image: imageData.data })
        .then((res) => {
          loader.hide()
          imageData.data = res.data.buffer
          imageData.fileType = res.data.fileType
        })
    }

    sendingImage.value = imageData
    imageInput.value.value = null
  }
}

function addMessageImageAction() {
  newMessage.value = 'Can I see '
  inputTextarea.value.focus()
  closeSendImageReminder()

  mixpanel.track('chat_image_ask_click', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })
}

$store.commit('RESET_PENDING_MODEL')

const showHints = computed(() => $store.getters.showHints)

function openModelInfo() {
  modelInfoOpened.value = true

  mixpanel.track('chat_open_model_info', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })
}

const sendImageReminder = ref(false)

function openSendImageReminder() {
  if (showHints.value) {
    sendImageReminder.value = true
  }
}

function closeSendImageReminder() {
  sendImageReminder.value = false
}

watch(
  model,
  async (val) => {
    if (!val) return

    newMessage.value = ''

    if (val.messages?.[0]?.initial === true) {
      $store.commit('SET_MESSAGES', val.messages)
      $store.commit('SET_HAS_MORE', false)
      return
    }

    $store.commit('SET_HAS_MORE', true)
    $store.commit('SET_MESSAGES', [])

    const modelId = model.value.id
    if (user.value) {
      const { messages, hasMore } = await $axios
        .get('/user/chat', {
          params: {
            limit: 20,
            offset: 0,
            modelId,
          },
        })
        .then((response) => response.data)
        .catch(() => {
          $store.commit('SET_HAS_MORE', false)
          return { messages: [], hasMore: false }
        })

      if ($store.state.selectedModel === val.id) {
        if (messages.length === 1 && !val?.messages) {
          const updatedModels = user.value.models.map((m) =>
            m.id === val.id ? { ...m, messages } : m,
          )
          const newUser = { ...user.value, models: updatedModels }
          $store.commit('SET_USER', newUser)
        }
        $store.commit('SET_HAS_MORE', hasMore)
        $store.commit('SET_MESSAGES', messages)
      }
    }

    $store.state.messages?.length && !$store.state.hasMore
  },
  { immediate: true },
)

const premadeModel = computed(() => $store.state.premadeModel)

watch(premadeModel, async (newVal, oldVal) => {
  if (newVal.id === oldVal.id && !oldVal.messages) return
  if (!typing) {
    setTyping(true)
  }
  const { messages } = await $axios
    .get('/model/premade/chat', {
      params: {
        modelId: premadeModel.value.id,
      },
    })
    .then((response) => response.data)
  setTyping(false)
  if (messages.length === 1 && !premadeModel.value?.messages) {
    const newPremadeModel = { ...premadeModel.value, messages }
    $store.commit('SET_PREMADE_MODEL', newPremadeModel)
  }
  await $store.commit('SET_HAS_MORE', false)
  await $store.commit('SET_MESSAGES', messages)
})

function getStepsValue(displayName, modelProperty) {
  let genderedStepsConfig

  if (model.value.gender == 'female') {
    if (model.value.style == 'realistic') {
      genderedStepsConfig = stepsConfig.stepsFemaleRealistic
    } else if (model.value.style == 'anime') {
      genderedStepsConfig = stepsConfig.stepsFemaleAnime
    }
  } else {
    if (model.value.style == 'realistic') {
      genderedStepsConfig = stepsConfig.stepsMaleRealistic
    } else if (model.value.style == 'anime') {
      genderedStepsConfig = stepsConfig.stepsMaleAnime
    }
  }

  const steps = genderedStepsConfig
    .filter((el) => el.displayName === displayName)[0]
    .options.filter((el) => el.value === modelProperty)
  return steps[0]
}

function chevronBack() {
  console.log('chevback', user.value)
  if (user.value) sidebarOpen.value = true
  else $router.push('/')
}

async function saveImage(message, saved) {
  if (!user.value) {
    openSubscribePopup()
    return
  }
  if (saved) {
    mixpanel.track('save_image', {
      chat_session_id: chatSessionId.value,
      chat_time_elapsed: chatSessionTimeElapsed(),
      model_name: model.value?.name,
      model_id: model.value?.id,
      model_metadata: model.value,
      from: 'chat',
      image_id: message.id,
    })
  } else {
    mixpanel.track('unsave_image', {
      chat_session_id: chatSessionId.value,
      chat_time_elapsed: chatSessionTimeElapsed(),
      model_name: model.value?.name,
      model_id: model.value?.id,
      model_metadata: model.value,
      from: 'chat',
      image_id: message.id,
    })
  }

  $store.commit('SAVE_MESSAGE', { id: message.id, saved })

  // For some reason ImageMessageItem doesn't automatically update so we do this
  selectedImageMessage.value = { ...message, saved }

  await $axios.post('/user/save-message', {
    messageId: message.id,
    saved,
  })
}

function toggleVoice() {
  if (!luna.value) {
    $store.commit('SET_NO_BALANCE_POPUP', {
      open: true,
    })
    $store.commit('DISABLE_VOICE_MESSAGE')
  } else {
    $store.commit('TOGGLE_VOICE_MESSAGE')

    mixpanel.track('chat_voice_message_toggle', {
      chat_session_id: chatSessionId.value,
      chat_time_elapsed: chatSessionTimeElapsed(),
      model_name: model.value?.name,
      model_id: model.value?.id,
      model_metadata: model.value,
      value: voiceMessageToggle.value,
    })
  }
}

function openSendImage() {
  imageInput.value.click()

  mixpanel.track('chat_open_send_image', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
  })
}

const addWaves = () => {
  waves.value++
}

if (!model.value) {
  sidebarOpen.value = true

  if (window.innerWidth > 1024 && !$route.query.modelId) {
    $store.commit(
      'SET_SELECTED_MODEL',
      $store.state.user.models.length ? $store.state.user.models[0].id : null,
    )
  }
}

if ($store.state.premadeModel) {
  setTyping(true)
}

if ($store.state.messages.length == 1) {
  setTyping(true)
  setTimeout(() => setTyping(false), 2000)
}

function regenerateImageEvent(messageId) {
  mixpanel.track('chat_regenerate_image', {
    chat_session_id: chatSessionId.value,
    chat_time_elapsed: chatSessionTimeElapsed(),
    model_name: model.value?.name,
    model_id: model.value?.id,
    model_metadata: model.value,
    id: messageId,
  })
}

function isFreeUnlock(message) {
  return (
    !user.value ||
    (!hasSubscription.value && !freeGeneratedImages.value.includes(message.id))
  )
}

function canOpenImagePopup(message) {
  return (
    user.value &&
    (hasSubscription.value ||
      freeGeneratedImages.value.includes(message.id) ||
      $store.state.guestImages.includes(message.id))
  )
}

onMounted(() => {
  document.addEventListener('click', closeGiftPanel)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeGiftPanel)
})
</script>

<template>
  <ImagePopup
    class="sticky z-50"
    :open="imagePopupOpen"
    :src="cdnFile(selectedImageMessage?.image)"
    :saved="selectedImageMessage?.saved"
    @update:open="imagePopupUpdated"
    @update:saved="(val) => saveImage(selectedImageMessage, val)"
  />
  <div
    ref="modelInfo"
    class="model-info-sidebar fixed top-0 h-full w-full z-[100] -right-full"
  >
    <div
      class="fixed top-0 h-full w-full lg:backdrop-blur-md"
      @click="modelInfoOpened = false"
    ></div>
    <div
      class="transition-all duration-300 w-[100%] lg:w-[80%] max-w-[calc(100%-26px)] bottom-[16px] lg:max-w-[365px] border border-white border-opacity-5 absolute bg-[#0A0D1E] rounded-[25px] p-3"
      :class="
        modelInfoOpened
          ? 'right-[50%] translate-x-1/2 lg:translate-x-0 lg:right-[14px] lg:bottom-[17px] top-[83px] lg:top-[17px]'
          : '-right-full top-[83px] lg:top-[17px]'
      "
    >
      <div
        class="z-20 translate-x-1/2 -translate-y-1/2 lg:bg-none absolute top-[5px] lg:top-0 right-[16px] lg:right-[unset] lg:-left-14 flex justify-center lg:pt-5"
      >
        <button
          type="button"
          class="flex justify-center items-center lg:p-2.5 focus:outline-none w-[43px] h-[43px] bg-[#CC47FF] rounded-[15px] lg:w-[unset] lg:h-[unset] lg:bg-transparent"
          @click="modelInfoOpened = false"
        >
          <span class="sr-only">Close sidebar</span>
          <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
      <div
        v-if="model"
        ref="modelInfoContent"
        class="h-full overflow-y-scroll rounded-[15px] [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
      >
        <div class="flex flex-col items-center relative">
          <div
            class="absolute top-0 w-full bottom-[75px] bg-black"
            style="
              background: linear-gradient(
                180deg,
                rgba(10, 13, 30, 0) 34.3%,
                #0a0d1e 100%
              );
            "
          ></div>
          <picture v-if="model.premadeId">
            <source
              media="(min-width: 768px)"
              :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}.avif`"
              type="image/avif"
            />
            <source
              media="(min-width: 768px)"
              :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}.webp`"
              type="image/webp"
            />

            <source
              media="(max-width: 767px)"
              :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}-sm.avif`"
              type="image/avif"
            />
            <source
              media="(max-width: 767px)"
              :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}-sm.webp`"
              type="image/webp"
            />
            <img
              class="w-full object-cover h-[513px] rounded-[15px]"
              style="user-select: none; user-drag: none; pointer-events: none"
              :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}.webp`"
              :alt="model?.name"
              draggable="false"
            />
          </picture>
          <img
            v-else
            class="w-full object-cover h-[513px] rounded-[15px]"
            :src="cdnFile(model?.referenceImage)"
            :alt="model?.name"
            style="user-select: none; user-drag: none; pointer-events: none"
            draggable="false"
          />
          <div
            class="flex flex-col gap-[15px] items-center mt-[15px] cursor-pointer"
          >
            <p class="text-[35px] leading-[23px]">
              {{ model?.name }}
            </p>
            <p class="text-[18px] text-[#B1B5DB]">{{ model?.age }} years</p>
          </div>
        </div>
        <div class="flex flex-col gap-[15px] mt-[15px] capitalize">
          <div class="bg-[#0E122A] w-full p-[26px] rounded-[15px]">
            <p class="text-[12px] text-[#B1B5DB]">Personality</p>
            <p class="">
              {{
                getStepsValue('Personality', model.personality)?.displayName ||
                model.personality ||
                model.customPersonality ||
                'Adaptive'
              }}
            </p>
          </div>
          <div v-if="model.occupation">
            <div class="bg-[#0E122A] w-full p-[26px] rounded-[15px]">
              <p class="text-[12px] text-[#B1B5DB]">Occupation</p>
              <p>
                {{
                  getStepsValue('Occupation', model.occupation)?.displayName ||
                  model.occupation
                }}
              </p>
            </div>
          </div>
          <template v-if="model.hobbies?.length">
            <template v-for="(hobby, index) in model.hobbies" :key="hobby">
              <div class="bg-[#0E122A] w-full p-[26px] rounded-[15px]">
                <p class="text-[12px] text-[#B1B5DB]">
                  {{
                    model.hobbies.length === 1 ? 'Hobby' : `Hobby ${index + 1}`
                  }}
                </p>
                <p>
                  {{ getStepsValue('Hobbies', hobby)?.displayName || hobby }}
                </p>
              </div>
            </template>
          </template>
          <template v-if="premadeModelLore">
            <div
              class="pb-[60px] lg:pb-0 text-sm lg:mt-[30px] [&>p]:mb-[10px] leading-relaxed lg:leading-relaxed text-justify text-[#B1B5DB]"
              v-html="premadeModelLore"
            ></div>
          </template>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg-[#070917] lg:ml-[476px] h-full max-h-full flex flex-col pb-[18px] lg:pr-[17px] lg:pl-0"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
  >
    <AppHeader class="lg:hidden"> </AppHeader>
    <div
      class="px-2 h-[calc(100%-74px)] lg:px-0 flex flex-col flex-1"
      :class="discountPopupOpen ? 'pt-[90px] lg:pt-[74px]' : 'lg:pt-[18px]'"
    >
      <div
        :class="modelInfoOpened ? 'hidden lg:flex' : ''"
        class="bg-[#0A0D1E] border-l lg:border-l-0 border-y border-r rounded-tl-[15px] lg:rounded-tl-none rounded-tr-[15px] border-white border-opacity-5 sticky top-0 z-30 flex py-[10px] lg:py-[17px] shrink-0 items-center gap-x-[10px] lg:gap-x-4 px-4 shadow-sm sm:gap-x-6 pr-[18px]"
      >
        <button
          type="button"
          class="lg:hidden focus:outline-none"
          @click="chevronBack"
        >
          <span class="sr-only">Open sidebar</span>
          <img :src="chevronLeft" aria-hidden="true" />
        </button>

        <div class="flex flex-1 justify-between">
          <div class="flex items-center">
            <div
              class="h-[42px] w-[42px] rounded-full overflow-hidden shrink-0"
            >
              <picture v-if="model?.premadeId">
                <source
                  media="(min-width: 768px)"
                  :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}.avif`"
                  type="image/avif"
                />
                <source
                  media="(min-width: 768px)"
                  :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}.webp`"
                  type="image/webp"
                />
                <source
                  media="(max-width: 767px)"
                  :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}-sm.avif`"
                  type="image/avif"
                />
                <source
                  media="(max-width: 767px)"
                  :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}-sm.webp`"
                  type="image/webp"
                />
                <img
                  class="w-full rounded-xl aspect-[832/1216]"
                  :src="`/models/webp/${$store.getters.model.name.toLowerCase()}-sm.webp`"
                  :alt="model?.name"
                  draggable="false"
                />
              </picture>
              <img
                v-else
                class="w-full rounded-xl aspect-[832/1216]"
                :src="cdnFile(model?.referenceImage)"
                :alt="model?.name"
                draggable="false"
              />
            </div>
            <div class="ml-3 mr-[38px]">
              <p class="text-[19px] leading-[22px]">
                {{ model?.name }}
              </p>
              <p class="text-[14px] text-white text-opacity-50">
                {{ model?.age }} years
              </p>
            </div>
          </div>
          <div class="flex items-center gap-x-[14px] lg:gap-x-[8px]">
            <LunaAmount class="hidden lg:flex" />
            <button
              class="lg:flex justify-center items-center gap-x-1 bg-[#11152D] w-[46px] h-[46px] p-[10px] cursor-pointer rounded-full"
              @click.prevent="
                hasSubscription ? toggleVoice() : openSubscribePopup(false)
              "
              style="box-shadow: 0px 0px 0px 1px #1e1a3d"
            >
              <component
                v-if="voiceMessageToggle"
                :is="Speaker"
                :size="28"
                color="#CC47FF"
              ></component>
              <img v-else class="h-6 w-6" :src="volumeX" />
            </button>
            <button
              class="lg:flex items-center gap-x-1 bg-[#11152D] p-[10px] cursor-pointer rounded-full"
              style="box-shadow: 0px 0px 0px 1px #1e1a3d"
              @click="openModelInfo()"
            >
              <img class="w-[26px] h-[26px]" :src="infoCircle" />
            </button>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col flex-1 overflow-hidden">
        <GenderPopup v-if="!user?.gender && !tempGender"></GenderPopup>
        <main
          ref="chatContainer"
          :class="modelInfoOpened ? 'opacity-0 lg:opacity-100 lg:flex' : ''"
          class="flex-1 flex flex-col-reverse gap-y-4 overflow-y-auto overflow-x-hidden py-4 px-2 md:px-4 lg:px-6 bg-[#0A0D1E] border-l lg:border-l-0 border-r border-white border-opacity-5"
          @scroll="infinteMessageLoad"
        >
          <template v-for="(message, i) in messages">
            <div
              v-if="message.role === 'assistant'"
              :key="i"
              class="flex items-center gap-x-[10px] sm:gap-x-[25px]"
              :class="{ 'mt-auto': i === messages.length - 1 }"
            >
              <div
                class="h-[42px] w-[42px] bg-gray-500 rounded-full overflow-hidden self-end"
              >
                <picture v-if="model?.premadeId">
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 768px)"
                    :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}.webp`"
                    type="image/webp"
                  />

                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/avif/${$store.getters.model.name.toLowerCase()}-sm.avif`"
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 767px)"
                    :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}-sm.webp`"
                    type="image/webp"
                  />
                  <img
                    class="w-full aspect-[832/1216]"
                    style="
                      user-select: none;
                      user-drag: none;
                      pointer-events: none;
                    "
                    :srcset="`/models/webp/${$store.getters.model.name.toLowerCase()}-sm.webp`"
                    :alt="model?.name"
                    draggable="false"
                  />
                </picture>
                <img
                  v-else
                  class="w-full rounded-xl aspect-[832/1216]"
                  :src="cdnFile(model?.referenceImage)"
                  :alt="model?.name"
                  style="
                    user-select: none;
                    user-drag: none;
                    pointer-events: none;
                  "
                  draggable="false"
                />
              </div>
              <div class="max-w-[70%] lg:max-w-[50%] flex flex-col gap-y-2">
                <div
                  v-for="(content, j) of message.contents"
                  :key="j"
                  class="relative py-[12px] px-[15px] rounded-[15px] rounded-bl-none bg-[#121732] text-white message text-[15px] leading-[26px]"
                >
                  <template v-if="content.content !== '__typing__'">
                    <template v-if="message.image || message.lunaToUnlock">
                      <ImageMessageItem
                        :message="message"
                        :free-unlock="isFreeUnlock(message)"
                        :instant-unlock="message.newMessage"
                        @img:click="
                          () => {
                            if (canOpenImagePopup(message)) {
                              openImagePopup(message)
                            } else {
                              openSubscribePopup('unlock_image')
                            }
                          }
                        "
                        @img:regenerate="regenerateImageEvent"
                        @update:saved="(val) => saveImage(message, val)"
                      />
                    </template>
                    <template v-if="message.voice">
                      <AudioPlayer
                        :class="message.image ? 'pt-[12px]' : ''"
                        :src="audioMessageLink(message.voice)"
                      ></AudioPlayer>
                    </template>
                    <template v-else>
                      <MessageItem
                        :content="content.content"
                        :class="
                          message.image || message.lunaToUnlock
                            ? 'lg:max-w-[512px] pt-3'
                            : ''
                        "
                      />
                    </template>
                    <!--                <template v-else>-->
                    <!--                  Error: Unsupported message type-->
                    <!--                </template>-->
                  </template>
                  <template v-else>
                    <div class="typing py-3 px-0.5">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div
              v-if="message.role === 'user'"
              :key="i"
              class="flex gap-x-3 items-end justify-end"
              :class="{ 'mt-auto': i == messages.length - 1 }"
            >
              <div class="max-w-[75%] lg:max-w-[50%] flex flex-col gap-y-2">
                <div
                  class="ml-auto py-[12px] px-[15px] rounded-[15px] rounded-br-none bg-[#121732] text-white message"
                >
                  <template v-for="(content, j) of message.contents" :key="j">
                    <img
                      v-if="message.gift"
                      class="w-40"
                      :src="giftImage(message.gift)"
                      style="
                        user-select: none;
                        user-drag: none;
                        pointer-events: none;
                      "
                      draggable="false"
                    />
                    <template v-else>
                      <template v-if="message.image">
                        <img
                          v-if="message.imageFileType"
                          class="rounded-xl w-full max-w-[512px] py-1.5"
                          :src="message.image"
                          style="
                            user-select: none;
                            user-drag: none;
                            pointer-events: none;
                          "
                          draggable="false"
                        />
                        <img
                          v-else
                          class="rounded-xl w-full max-w-[512px] py-1.5"
                          :src="cdnFile(message.image, true)"
                          style="
                            user-select: none;
                            user-drag: none;
                            pointer-events: none;
                          "
                          draggable="false"
                        />
                      </template>
                      <template v-if="message.voice">
                        <AudioPlayer
                          v-if="message.voiceFileType"
                          :src="message.voice"
                          :base-duration="message.voiceDuration"
                        ></AudioPlayer>
                        <AudioPlayer
                          v-else
                          :src="audioMessageLink(message.voice)"
                        ></AudioPlayer>
                      </template>
                      <MessageItem v-else :content="content.content" />
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <div class="absolute bottom-0" ref="endRef"></div>
          <div
            v-show="!messagesFullyLoaded"
            class="w-full flex justify-center mb-10"
          >
            <div class="loader w-10 h-10"></div>
          </div>
        </main>
        <div
          :class="modelInfoOpened ? 'hidden lg:flex' : ''"
          class="relative bg-[#0A0D1E] border-r border-white border-opacity-5 sticky bottom-0 lg:border-b z-30 flex flex-col lg:pb-[15px] lg:pt-[11px] shrink-0 justify-center gap-x-4 shadow-sm sm:gap-x-6 md:px-4 lg:px-[14px] lg:mb-0 rounded-b-2xl lg:rounded-bl-none"
        >
          <div
            v-show="sendImageReminder"
            class="relative bg-purple-600 text-sm rounded-t-2xl p-2"
          >
            You can ask {{ model?.name }} to send you specific pictures or click
            the image shortcut.
            <!-- <XMarkIcon class="h-6 absolute top-2 right-2 cursor-pointer" @click.prevent="closeSendImageReminder"></XMarkIcon> -->
          </div>
          <div
            v-if="sendingImage"
            class="bg-[#0E122A] rounded-t-2xl border-b border-neutral-700 p-2 pb-4 pl-4"
          >
            <div
              class="relative rounded-xl bg-[#33333377] max-w-[100px] md:max-w-[200px] flex justify-center items-center"
            >
              <img
                class="rounded-xl max-w-full max-h-full"
                :src="sendingImage.data"
                style="user-select: none; user-drag: none; pointer-events: none"
                draggable="false"
              />
              <button
                class="absolute top-1 right-1 flex justify-center items-center bg-[#11152D] w-[35px] h-[35px] rounded-full"
                style="
                  box-shadow:
                    0px 0px 0px 1.2px #1e1a3d,
                    0px 0px 12px 2.4px #13142e inset;
                "
                @click.prevent="() => (sendingImage = null)"
              >
                <img :src="xClose" class="h-6 w-6 -translate-x-[0.5px]" />
              </button>
            </div>
          </div>
          <form
            class="relative flex flex-col flex-1 gap-x-4 lg:gap-x-6"
            :class="
              (sendImageReminder || sendingImage
                ? 'rounded-b-2xl'
                : 'rounded-2xl',
              modelInfoOpened ? 'hidden lg:flex' : '')
            "
            @submit.prevent="sendForm"
          >
            <textarea
              ref="inputTextarea"
              v-model="newMessage"
              autocapitalize="sentences"
              autocomplete="off"
              aria-label="message"
              :class="
                sendImageReminder || sendingImage
                  ? 'rounded-t-none rounded-b-2xl'
                  : 'lg:rounded-2xl'
              "
              class="w-full min-h-[70px] bg-[#0E122A] border border-[#141938] focus:ring-0 placeholder:text-[#AAAAAA] text-white px-4 pr-[60px] resize-none py-[22px] max-h-[120px] pl-[74px] sm:pl-[84px] [&::-webkit-scrollbar]:hidden rounded-b-2xl"
              type="text"
              :placeholder="
                isRecording || sendingAudio ? '' : 'Type your message..'
              "
              rows="1"
              :readonly="isRecording || sendingAudio"
              @keydown.enter.exact.prevent="sendForm"
              @input="adjustTextarea"
            ></textarea>
            <div
              v-if="isRecording || sendingAudio"
              class="absolute top-[50%] left-[40%] -translate-y-[50%] waves flex lg:hidden gap-[5px] items-center"
            >
              <div
                v-for="wave in Array(waves)
                  .fill(0)
                  .map((_, i) => i)
                  .splice(-maxWavesMobile)"
                :key="wave"
                class="bg-white w-1 rounded-full"
                :style="`height:${waveSizes[wave % waveSizes.length]}px`"
              ></div>
            </div>
            <div
              v-if="isRecording || sendingAudio"
              class="hidden lg:flex gap-[30px] items-center absolute bottom-1/2 left-[50%] -translate-x-[50%] px-[15px] py-4 bg-[#0E122A] rounded-[100px] w-[468px] border border-[#B651FF]"
              style="box-shadow: 0px 0px 0px 15px #af44ff0d"
            >
              <button
                @click.prevent="discardRecording"
                class="absolute top-[50%] left-[-10%] -translate-y-1/2"
              >
                <img :src="xClose" class="h-[26px] w-[26px]" />
              </button>
              <button
                type="submit"
                class="p-[14px] bg-[#CC47FF] rounded-full border border-white border-opacity-15 absolute bottom-[16px] right-[15px]"
                style="box-shadow: 0px 0px 0px 1.81px #b552ff"
              >
                <img :src="send" class="h-[26px] w-[26px]" />
              </button>
              <button
                v-if="isRecording"
                @click.prevent="stopRecording"
                class="p-[14px] flex justify-center items-center h-[55px] w-[55px] bg-[#CC47FF] rounded-full border border-white border-opacity-15"
                style="box-shadow: 0px 0px 0px 1.81px #b552ff"
              >
                <img
                  :src="pauseCircleOutline"
                  class="h-[32px] w-[32px] max-w-none"
                />
              </button>
              <div v-else>
                <button
                  v-if="!isPlayingSendingAudio"
                  @click.prevent="
                    () => {
                      sendingAudioPlayer.play()
                      isPlayingSendingAudio = true
                    }
                  "
                  class="p-[14px] flex justify-center items-center h-[55px] w-[55px] bg-[#CC47FF] rounded-full border border-white border-opacity-15"
                  style="box-shadow: 0px 0px 0px 1.81px #b552ff"
                >
                  <img :src="play" class="h-[26px] w-[26px]" />
                </button>
                <button
                  v-else
                  @click.prevent="
                    () => {
                      sendingAudioPlayer.pause()
                    }
                  "
                  class="p-[14px] flex justify-center items-center h-[55px] w-[55px] bg-[#CC47FF] rounded-full border border-white border-opacity-15"
                  style="box-shadow: 0px 0px 0px 1.81px #b552ff"
                >
                  <img
                    :src="pauseCircleOutline"
                    class="h-[32px] w-[32px] max-w-none"
                  />
                </button>
              </div>
              <div class="waves flex gap-[5px] items-center">
                <div
                  v-for="wave in Array(waves)
                    .fill(0)
                    .map((_, i) => i)
                    .splice(-maxWaves)"
                  :key="wave"
                  class="bg-white w-1 rounded-full"
                  :style="`height:${waveSizes[wave % waveSizes.length]}px`"
                ></div>
              </div>
            </div>
            <audio
              ref="sendingAudioPlayer"
              :src="sendingAudio?.data"
              hidden
              @timeupdate="
                (e) => (playingDuration = e.target.currentTime * 1000)
              "
              @pause="() => (isPlayingSendingAudio = false)"
              @ended="
                () => {
                  playingDuration = 0
                  isPlayingSendingAudio = false
                }
              "
            ></audio>
            <input
              ref="imageInput"
              accept="image/*"
              type="file"
              hidden
              @change="imageSelect"
            />
            <!-- Keep type="button" else these will try to submit the form-->
            <ButtonComponent
              v-if="!isRecording && !sendingAudio"
              class="focus:outline-none cursor-pointer p-[9px] absolute top-[50%] -translate-y-[50%] left-[15px]"
              @click="
                hasSubscription ? openSendImage() : openSubscribePopup(false)
              "
              type="button"
            >
              <img :src="camera" />
            </ButtonComponent>
            <ButtonComponent
              v-else-if="isRecording"
              class="block lg:hidden focus:outline-none cursor-pointer p-[9px] absolute top-[50%] -translate-y-[50%] left-[70px]"
              @click="stopRecording()"
              type="button"
            >
              <img class="h-[26px] w-[26px]" :src="pauseCircleOutline" />
            </ButtonComponent>
            <div v-else-if="sendingAudio">
              <ButtonComponent
                v-if="!isPlayingSendingAudio"
                @click="
                  () => {
                    sendingAudioPlayer.play()
                    isPlayingSendingAudio = true
                  }
                "
                type="button"
                class="block lg:hidden focus:outline-none cursor-pointer p-[9px] absolute top-[50%] -translate-y-[50%] left-[70px]"
              >
                <img class="h-[26px] w-[26px]" :src="play" />
              </ButtonComponent>
              <ButtonComponent
                v-else
                @click="
                  () => {
                    sendingAudioPlayer.pause()
                  }
                "
                type="button"
                class="block lg:hidden focus:outline-none cursor-pointer p-[9px] absolute top-[50%] -translate-y-[50%] left-[70px]"
              >
                <img class="h-[26px] w-[26px]" :src="pauseCircle" />
              </ButtonComponent>
            </div>
            <ButtonComponent
              v-if="isRecording || sendingAudio"
              @click="discardRecording"
              type="button"
              class="block lg:hidden focus:outline-none cursor-pointer p-[9px] absolute top-[50%] -translate-y-[50%] left-[15px]"
            >
              <img :src="xClose" class="h-[26px] w-[26px]" />
            </ButtonComponent>
            <div
              class="absolute top-[50%] -translate-y-[50%] right-[14px] z-10 flex gap-[9px]"
            >
              <div
                v-if="
                  !(
                    newMessage.trim() ||
                    sendingImage ||
                    isRecording ||
                    sendingAudio
                  )
                "
                class="flex items-center gap-2"
              >
                <!-- Keep type="button" else these will try to submit the form-->
                <button @click="startRecording" type="button">
                  <img class="w-6 h-6" :src="microphone" />
                </button>
                <button
                  @click="addMessageImageAction"
                  type="button"
                  class="rounded-md"
                  :class="{
                    'bg-purple-600': sendImageReminder,
                  }"
                  :style="
                    sendImageReminder
                      ? 'box-shadow: 0px 0px 0px 4px rgb(147, 51, 234)'
                      : ''
                  "
                >
                  <img class="w-6 h-6" :src="imageUp" />
                </button>
                <button
                  ref="toggleGiftPanelButton"
                  @click="openGiftPanel"
                  type="button"
                >
                  <img class="w-6 h-6" :src="gift" />
                </button>
              </div>
              <ButtonComponent
                v-if="
                  newMessage.trim() ||
                  sendingImage ||
                  isRecording ||
                  sendingAudio
                "
                class="focus:outline-none cursor-pointer p-[9px]"
                type="submit"
              >
                <img :src="send" />
              </ButtonComponent>
            </div>
            <div
              v-show="sendingImage && !isRecording && !sendingAudio"
              class="items-center gap-[15px] absolute right-0 top-[50%] translate-y-[-50%] pt-[22px] pb-[16px] mr-[12px]"
            >
              <button
                v-show="sendingImage && !isRecording && !sendingAudio"
                class="focus:outline-none cursor-pointer text-white box-content"
                :class="sendingImage ? 'mr-[60px]' : ''"
                @click.prevent="startRecording()"
              >
                <img :src="microphone" />
              </button>
            </div>
          </form>
          <div
            v-if="
              !newMessage.trim() &&
              !sendingImage &&
              !isRecording &&
              !sendingAudio
            "
            class="absolute w-full bottom-1/2 lg:w-fit lg:right-[14px] lg:bottom-[96px] inline-block text-left"
          >
            <div
              v-if="giftPanelOpen"
              class="lg:hidden fixed z-[60] top-0 left-0 right-0 bottom-0 backdrop-blur-md"
            ></div>
            <transition
              :show="true"
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <div
                v-if="giftPanelOpen"
                @close="closeGiftPanel"
                class="z-[70] fixed bottom-1/2 lg:bottom-0 lg:relative p-[13px] block bottom-[50%] left-[20px] lg:left-0 right-[20px] translate-y-1/2 lg:translate-x-0 lg:translate-y-0 lg:bottom-0 lg:right-0 origin-top-right rounded-[20px] bg-[#0E122A] shadow-lg ring-1 ring-black/5 focus:outline-none border border-[#171D40]"
              >
                <div>
                  <button
                    @click="closeGiftPanel"
                    class="block lg:hidden ml-auto mr-[9px] mb-[13px]"
                  >
                    <img :src="xClose" />
                  </button>
                </div>
                <div class="grid grid-cols-3 gap-x-3 gap-y-[7px]">
                  <button
                    v-for="(gift, i) of gifts"
                    :key="i"
                    class="rounded-[13px] lg:w-[100px] aspect-square cursor-pointer flex justify-center items-center bg-[#141835] hover:bg-gray-600"
                    @click="
                      hasSubscription ? buyGift(i) : openSubscribePopup(false)
                    "
                  >
                    <div>
                      <img
                        class="w-[44px] aspect-square"
                        :class="gift.price > user?.luna ? 'grayscale' : ''"
                        :src="cdnFile(gift.image, true)"
                        style="
                          user-select: none;
                          user-drag: none;
                          pointer-events: none;
                        "
                        draggable="false"
                      />
                      <div
                        class="flex text-white items-center justify-center gap-1.5 mt-[14px]"
                        :class="gift.price > user?.luna ? 'text-gray-500' : ''"
                      >
                        <img
                          class="w-[17px]"
                          :src="logoicon"
                          :style="{
                            filter:
                              gift.price > user?.luna
                                ? 'brightness(0.5)'
                                : 'none',
                          }"
                        />
                        <div class="text-[14px] md:xs">
                          {{ gift.price }}
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.message {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #737373;
  border-radius: 50%;
  height: 9px;
  margin-right: 5px;
  vertical-align: middle;
  width: 9px;
  display: inline-block;
}

.typing .dot:last-child {
  margin-right: 0;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #d168fa;
  }

  28% {
    transform: translateY(-7px);
    background-color: #dc83ff;
  }

  44% {
    transform: translateY(0px);
    background-color: #e49fff;
  }
}
</style>
