<script setup>
import { reactive, computed, toRefs } from 'vue'
import plusCircle from '@/assets/icons/plus-circle.svg'
import minus from '@/assets/icons/minus.svg'
import { useMixpanel } from '@/composables/mixpanel'

const mixpanel = useMixpanel()

const props = defineProps({
  gender: String,
  style: String,
  type: {
    type: String,
    default: '', // Default value for type prop
  },
})

const { gender, style, type } = toRefs(props)

const isOpen = reactive({})

const faqsGirlfriend = [
  {
    id: 'gf-faq1',
    question: `How does a virtual AI girlfriend work?`,
    answer: `An AI girlfriend is your own virtual companion, designed to connect with you on a deeper level. She can do literally anything to meet even your wildest desires. She's there to chat with you, send you spicy pictures, interact like real-life communication, adapt to your style, and grow with you.`,
  },
  {
    id: 'gf-faq2',
    question: `Can I customize my AI gf's appearance?`,
    answer: `Absolutely! Want her to have piercing blue eyes or fiery red hair? You got it. Design your AI girlfriend's looks from head to toe, making sure she's exactly your type.`,
  },
  {
    id: 'gf-faq3',
    question: `Can I teach my AI girlfriend new things?`,
    answer: `Definitely! The more you chat with your AI girlfriend, the more she'll get to know you. She'll pick up on your jokes, remember your stories, and even start using your favorite emojis. It's like having a real-life relationship with your perfect AI girl.`,
  },
  {
    id: 'gf-faq4',
    question: `Can I have multiple AI girlfriends at once?`,
    answer: `Whether you want to develop a bond with one AI girlfriend, or a harem of AI wives, the choice is yours. There's no limit to the number of AI girlfriends you can create on our platform.`,
  },
  {
    id: 'gf-faq5',
    question: 'Can she send spicy pictures?',
    answer:
      'Of course! Want to see your AI girlfriend in that new lingerie you picked out for her? Or no lingerie? Go for it! And you can send pics back too! Our uncensored chat means you can explore your wildest fantasies together.',
  },
  {
    id: 'gf-faq6',
    question: 'Are my conversations private?',
    answer:
      'We take your privacy seriously. All conversations are confidential, and we use the latest encryption tech to protect your data. So go ahead, be yourself, and let your imagination run wild.',
  },
]

const faqsBoyfriend = [
  {
    id: 'bf-faq1',
    question: `How does a virtual AI boyfriend work?`,
    answer: `An AI boyfriend is a virtual partner who can make your secret desires and wildest dreams come true. He'll surprise you with his ability to chat, share pics that'll make you blush, and keep you coming back for more.`,
  },
  {
    id: 'bf-faq2',
    question: `Can I customize my AI bf's appearance?`,
    answer:
      'Yes, you can! From his chiseled jawline to his mesmerizing eyes, those strong hands holding yours, or that mischievous grin making you weak in the knees—every feature is yours to mold.',
  },
  {
    id: 'bf-faq3',
    question: `Can I teach my AI boyfriend new things?`,
    answer: `You bet! Every conversation deepens the bond. Your AI boyfriend has a heart (and a memory!) that grows with every conversation. Spill your secrets, and share your dream - he'll remember everything and do exactly what you want to fulfill your desires.`,
  },
  {
    id: 'bf-faq4',
    question: `Can I have multiple AI boyfriends at once?`,
    answer: `Of course! Build your own league of AI boyfriends, each one uniquely crafted to fulfill your every fantasy and desire. From the boy next door to the mysterious bad boy - your options are limitless.`,
  },
  {
    id: 'bf-faq5',
    question: `Can he send pictures?`,
    answer: `Yeah, he can! Your AI boyfriend is your virtual dress-up doll, ready to model any look you can dream up.  Tell him to dress in a sexy outfit and watch the temperature rise.`,
  },
  {
    id: 'bf-faq6',
    question: 'Is my privacy protected?',
    answer: `Yes, of course! Whisper your secrets, share your dreams... he's all yours. Our commitment to privacy means your conversations stay between you and your AI boyfriend. We've got your back with the latest security tech, so you can explore your deepest desires and let your fantasies unfold.`,
  },
]

const faqsHentai = [
  {
    id: 'he-faq1',
    question: `How does a virtual AI hentai work?`,
    answer: `An AI hentai is your virtual waifu or yaoi who can make your deepest fantasies come true. From spicy chats to sweaty selfies, they're here to fulfill your deepest desires. The best part is they'll adapt to your style and grow with you, as long as you keep talking to them.`,
  },
  {
    id: 'he-faq2',
    question: `Can I customize my AI hentai's appearance?`,
    answer: `Yes, you can! Customize their every detail, from those captivating eyes to that flowing hair. Whether you're into the classic schoolgirl/schoolboy look or something a little more daring, your AI hentai will be everything you've ever dreamed of`,
  },
  {
    id: 'he-faq3',
    question: `Can I teach my AI hentai new things?`,
    answer: `The more you chat, the more they'll understand your unique quirks and preferences. And if you want something new from them, just ask! They're all ears, and they're quick learners too.`,
  },
  {
    id: 'he-faq4',
    question: `Can I generate multiple AI hentais?`,
    answer: `Your desires, your rules. Craft an entire harem of AI waifus or yaoi, each one tailored to your specific tastes. Whether you crave tsundere antics, dandere devotion, or even yandere intensity, the power is in your hands.`,
  },
  {
    id: 'he-faq5',
    question: 'Can my AI hentai send spicy pictures?',
    answer: `Of course! Dress them up, show them off! Your AI hentai is ready for a cosplay session, and you're the photographer. Snap some pics, and let your imaginations run wild. No request is too spicy for your AI hentai.`,
  },
  {
    id: 'he-faq6',
    question: 'Is my chat history kept private?',
    answer:
      'Your secrets are safe with us. We use top-notch encryption to keep your conversations private, so you can explore your deepest desires with your AI hentai and let your imagination run wild.',
  },
]

const faqsAffiliate = [
  {
    id: 'af-faq1',
    question: 'How fast can you sign me up?',
    answer:
      "We respond to affiliate requests within 24 hours, if not sooner. You'll receive an email with your credentials to our affiliate platform.",
  },
  {
    id: 'af-faq2',
    question: 'Do you have any restrictions on traffic sources?',
    answer:
      "No, we allow you to drive traffic from anywhere you'd like, whether it's organic, PPC, or anything else.",
  },
  {
    id: 'af-faq3',
    question: 'How much do I get paid?',
    answer:
      'We have a $35 CPA/PPS deal. For every paid customer you bring, you get paid $35.',
  },
  {
    id: 'af-faq4',
    question: 'How often do I get paid?',
    answer:
      'The payments are done on a weekly basis, every Monday. You are able to request a payment through our self-serve affiliate platform.',
  },
  {
    id: 'af-faq5',
    question: 'Do you provide support?',
    answer:
      "Yes! We'll be able to provide you with copy, creatives, and LPs that we know convert well for your traffic source, as well as provide ongoing support.",
  },
  {
    id: 'af-faq6',
    question: 'Are you going to scrub me?',
    answer:
      "Nope, we don't do that here. Every click and conversion will be accounted for on your dashboard and you'll be paid what you bring. We value honest relationships with our partners.",
  },
]

const faqsGendered = computed(() => {
  if (type.value === 'affiliate') return faqsAffiliate

  if (style.value === 'anime') return faqsHentai

  return gender.value == 'male' ? faqsBoyfriend : faqsGirlfriend
})

function toggleFaq(faq) {
  if (!isOpen[faq.id])
    mixpanel.track('explore_faq_click', { selection: faq.question })

  isOpen[faq.id] = !isOpen[faq.id]
}
</script>

<template>
  <div>
    <h2 class="text-[25px] pb-[20px]">Frequently Asked Questions</h2>
    <div
      class="faq-container lg:border border-white border-opacity-5 lg:p-[15px] lg:bg-[#0A0D1E] rounded-[15px]"
    >
      <div>
        <div
          v-for="faq in faqsGendered"
          :key="faq.id"
          class="[&:not(:last-child)]:mb-[15px] lg:[&:not(:last-child)]:mb-5 bg-[#111026] px-[18px] lg:px-[28px] rounded-[12px] border border-[#1C1B38]"
        >
          <div
            :id="faq.id"
            class="flex gap-8 items-center py-[15px] lg:py-[28px] cursor-pointer w-full"
            @click="toggleFaq(faq)"
          >
            <h3 class="text-white w-full">
              {{ faq.question }}
            </h3>
            <img v-if="!isOpen[faq.id]" :src="plusCircle" alt="Plus icon" />
            <img
              class="h-[26px] w-[26px]"
              v-else
              :src="minus"
              alt="Minus icon"
            />
          </div>
          <p
            class="pb-4"
            :class="{ hidden: !isOpen[faq.id] }"
            v-html="faq.answer"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
