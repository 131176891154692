<script setup>
import ButtonComponent from './ButtonComponent.vue'
import particles from '@/assets/img/particles.svg'
import circles from '@/assets/img/circles.svg'
import magicWand from '@/assets/icons/magic-wand.svg'
import { computed } from 'vue'
import { toRefs } from 'vue'

const props = defineProps({
  gender: String,
  style: String,
})

const { gender, style } = toRefs(props)

const modelsShowcased = {
  female: {
    realistic: ['aaliyah', 'nari', 'amara', 'riley'],
    anime: ['aria', 'akari', 'ami', 'ayumi'],
  },
  male: {
    realistic: ['sean', 'amir', 'logan', 'tyler'],
    anime: ['ash', 'daichi', 'kai', 'elric'],
  },
}

const createUrl = computed(() => {
  if (style.value == 'anime') {
    if (gender.value === 'female') {
      return '/create-model?style=anime&gender=female'
    } else {
      return '/create-model?style=anime&gender=male'
    }
  }

  if (gender.value === 'female') {
    return '/create-girlfriend'
  } else {
    return '/create-boyfriend'
  }
})
</script>

<template>
  <div
    class="flex justify-center items-center bg-[#0A0D1E] rounded-[25px] py-[56px] px-4 lg:py-[30px] 2xl:p-0 border border-white border-opacity-5 lg:min-h-[345px] relative overflow-hidden"
  >
    <!-- We set the style to none using `style` so that the browser doesn't download this on mobile before the Tailwind CSS is loaded -->
    <!-- The mobile versions of the images are hidden on mobile, but we add them in the picture element just in case some browsers want to load what's there even with display: none -->
    <div style="display: none" class="lg:!block">
      <div
        class="z-10 p-[0.6px] rounded-full absolute top-[62px] left-[88px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][0]}.avif`"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][0]}.webp`"
            type="image/webp"
            media="(max-width: 1023px)"
          />

          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][0]}-sm.avif`"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][0]}-sm.webp`"
            type="image/webp"
            media="(min-width: 1024px)"
          />

          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="`/models/webp/${modelsShowcased[gender][style][0]}-sm.webp`"
            :alt="modelsShowcased[gender][style][0]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute rounded-full bottom-[59px] left-[50px] 2xl:left-[168px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][1]}.avif`"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][1]}.webp`"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][1]}-sm.avif`"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][1]}-sm.webp`"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="`/models/webp/${modelsShowcased[gender][style][1]}-sm.webp`"
            :alt="modelsShowcased[gender][style][1]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute bottom-[74px] right-[50px] 2xl:right-[163px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][2]}.avif`"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][2]}.webp`"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][2]}-sm.avif`"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][2]}-sm.webp`"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="`/models/webp/${modelsShowcased[gender][style][2]}-sm.webp`"
            :alt="modelsShowcased[gender][style][1]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute top-[72px] right-[55px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][3]}.avif`"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][3]}.webp`"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="`/models/avif/${modelsShowcased[gender][style][3]}-sm.avif`"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="`/models/webp/${modelsShowcased[gender][style][3]}-sm.webp`"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="`/models/webp/${modelsShowcased[gender][style][3]}-sm.webp`"
            :alt="modelsShowcased[gender][style][3]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <img
        class="absolute bottom-[0] left-[50%] translate-x-[-50%] rounded-full select-none"
        loading="lazy"
        :src="particles"
        alt="Particles background image"
        style="-webkit-user-drag: none"
      />
    </div>
    <!-- We set the style to none using `style` so that the browser doesn't download this on mobile before the Tailwind CSS is loaded -->
    <img
      class="lg:!block absolute top-[50%] w-[90%] translate-y-[-50%] select-none"
      loading="lazy"
      :src="circles"
      alt="Cicrles background image"
      style="display: none; -webkit-user-drag: none"
    />
    <div
      class="flex flex-col items-center gap-6 lg:gap-[36px] lg:w-[50%] text-center relative z-20"
    >
      <h2 class="text-[25px] leading-[33px] lg:text-[35px] lg:leading-[47px]">
        <template v-if="style == 'anime'">
          Your Fantasies Come True With An AI Hentai Character
        </template>
        <template v-else-if="gender == 'female'">
          Design the AI Girlfriend You've Always Desired
        </template>
        <template v-else-if="gender == 'male'">
          Your Romantic AI Boyfriend is Ready For You
        </template>
      </h2>
      <p class="text-[16px]">
        <template v-if="style == 'anime'">
          Will they be a tsundere with a hidden soft side or a bubbly/genki
          always ready for an adventure? Just one click to figure out! Imagine
          late-night chats filled with flirty stories, pics that'll make you
          sweat
        </template>
        <template v-else-if="gender == 'female'">
          Ready to meet your new virtual AI girlfriend? She's eager to sext you,
          send you spicy pictures, and help you explore your deepest desires.
          Design her looks, craft her personality, and watch her come alive on
          our love simulator.
        </template>
        <template v-else-if="gender == 'male'">
          Your perfect AI boyfriend is just a click away. Design your dream
          guy—from his charming smile to his captivating personality—and watch
          him come to life. He's ready to chat with you, send you his spicy
          pics, and help you explore your wildest dreams.
        </template>
      </p>
      <ButtonComponent
        :to="createUrl"
        class="flex items-center gap-[15px] py-[10px] pl-[11px] pr-[19px] text-[14px]"
      >
        <img class="w-6 h-6" :src="magicWand" alt="Magic wand icon" />
        {{ style !== 'anime' ? 'Create' : 'Generate' }} AI
        {{
          style === 'anime'
            ? 'Hentai'
            : gender === 'female'
              ? 'Girlfriend'
              : 'Boyfriend'
        }}
      </ButtonComponent>
    </div>
  </div>
</template>
